<template>
  <div class="tw-flex ctk-user-info">
    <CtkAvatar
      :user="getUserInfos"
      class="tw-my-auto tw-mr-2 tw-flex-shrink-0 tw-flex-grow-0"
    />
    <div class="header__infos tw-leading-tight tw-flex tw-flex-1 tw-flex-col tw-truncate tw-text-gray-700">
      <div
        class="header__firstname tw-text-xl tw-font-medium tw-truncate"
        v-text="getUserFullName"
      />
      <HoverOnCroppable
        :value="getUserInfos && getUserInfos.email"
        class="header__email tw-text-sm tw-truncate tw-text-gray-600"
        data-test="email"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import HoverOnCroppable from '@/components/UI/HoverOnCroppable/index.vue'

  /**
   * @module component - CtkUserInfo
   */
  export default defineComponent({
    name: 'CtkUserInfo',
    components: {
      CtkAvatar,
      HoverOnCroppable
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos',
        'getUserFullName'
      ])
    }
  })
</script>

<template>
  <ctk-alert-banner
    :title="$tc('app.paragraphs.unpaid_shipments', getShipmentsMetrics.counts.requires_payment.total)"
    class="ctk-unpaid-shipments"
    variant="info"
    @click.native="view"
  >
    <p class="tw-mb-0">
      <span
        v-text="$t(getShipmentsMetrics.counts.requires_payment.total > 1
          ? 'app.paragraphs.unpaid_shipments.many_shipments'
          : 'app.paragraphs.unpaid_shipments.single_shipment', {
          reference: getShipmentsMetrics.counts.requires_payment.shipment_reference
        })"
        data-test="inner-content"
      />
      <span
        v-if="hasLink"
        v-text="getShipmentsMetrics.counts.requires_payment.total > 1
          ? $t('app.paragraphs.unpaid_shipments.view_shipments')
          : $t('app.paragraphs.unpaid_shipments.view_shipment')"
        class="ui-link tw-ml-1"
        data-test="inner-link"
      />
    </p>
  </ctk-alert-banner>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Hotjar from '@/plugins/VueHotjar'

  import CtkAlertBanner from '@/components/CtkAlertBanners/_subs/CtkAlertBanner/index.vue'

  /**
   * @module component - CtkUnpaidShipments
   */
  export default {
    name: 'CtkUnpaidShipments',
    components: {
      CtkAlertBanner
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      /**
       * Returns true if the link is shown according to the route and the
       * unpaid shipments.
       * @function hasLink
       * @returns {boolean}
       */
      hasLink () {
        const {
          total,
          in_progress: inProgress,
          planned,
          shipment_uuid: shipmentUuid
        } = this.getShipmentsMetrics.counts.requires_payment
        const { name, params } = this.$route

        if (total === 1) {
          if (name === 'Shipment' && params.uuid === shipmentUuid) return false
        } else {
          if (name === 'Shipments') {
            if (params.state === 'in_progress' && inProgress > 0) return false
            if (params.state === 'planned' && planned > 0) return false
          }
        }

        return true
      }
    },
    mounted () {
      Hotjar.tag('Defaulting Shipment Shipper')
    },
    methods: {
      /**
       * Called when clicked on the banner, should redirect
       * the user to the right view according to counts
       * @function view
       */
      view () {
        if (!this.hasLink) return

        const {
          planned,
          total,
          shipment_uuid: shipmentUuid
        } = this.getShipmentsMetrics.counts.requires_payment
        if (total === 1) {
          this.$router.push({
            name: 'Shipment',
            params: {
              uuid: shipmentUuid
            }
          })
            .catch(() => {})
        } else if (total > 1) {
          this.$router.push({
            name: 'Shipments',
            params: {
              state: planned > 0 ? 'planned' : 'in_progress'
            }
          })
            .catch(() => {})
        }
      }
    }
  }
</script>

<template>
  <img
    v-if="showGenericAvatar"
    src="@/assets/img/icons/no-avatar.svg"
    class="ctk-avatar tw-border"
    alt=""
    data-test="no-driver-avatar"
  >
  <div
    v-else
    :style="avatarStyle"
    class="ctk-avatar tw-rounded-full tw-text-center tw-select-none tw-text-sm"
    aria-hidden="true"
    v-text="initials"
    data-test="driver-avatar"
  />
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'
  import useColorUuid from '@/composables/useColorUuid'

  /**
   * @module component - ctkAvatar
   * @param {Object} user
   */
  export default defineComponent({
    name: 'CtkAvatar',
    props: {
      user: {
        type: Object,
        required: false,
        default: null
      },
      white: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { user } = toRefs(props)
      const { getColorFromUuid } = useColorUuid()

      const isNoAccessStatusUser = computed(() => user && user.value && user.value.status && user.value.status === 'no_access')

      const initials = computed(() => {
        if (!user.value || !!isNoAccessStatusUser.value) return null

        const { first_name: firstName, last_name: lastName } = user.value
        return `${firstName.trim().charAt(0)}${lastName.trim().charAt(0)}`.toUpperCase()
      })

      const avatarStyle = computed(() => {
        if (!user.value) return null

        const avatarColor = getColorFromUuid(user.value.uuid)
        const { h, s, l } = avatarColor.background

        return {
          backgroundColor: `hsl(${Math.round(h)}, ${Math.round(s)}%, ${Math.round(l)}%)`,
          color: avatarColor.foreground
        }
      })

      /**
       * * Show the generic avatar for users with no_access status
       * * or when no user.value.uuid is provided
       */
      const showGenericAvatar = computed(() => {
        // * parentheses written to ease the reading
        const hasNoUuidProvided = !user ||
          (user && !user.value) ||
          (user && user.value && !user.value.uuid)

        return !!isNoAccessStatusUser.value || hasNoUuidProvided
      })

      return {
        avatarStyle,
        initials,
        isNoAccessStatusUser,
        showGenericAvatar
      }
    }
  })
</script>

<style lang="scss" scoped>

  $size: 40px;

  .ctk-avatar {
    height: $size;
    width: $size;
    line-height: $size;
  }

</style>

import { CompanyMission, MeMission } from '@/resources'

export default (/** @type {import('../AxiosResource').AxiosResourceConfig} */ config) => {
  // @ts-ignore
  const companyPathExpression = !!CompanyMission && !!CompanyMission.path && CompanyMission.path.expression
  // @ts-ignore
  const mePathExpression = !!MeMission && !!MeMission.path && MeMission.path.expression
  // @ts-ignore
  const configUriTemplateExpression = !!config && !!config.uriTemplate && config.uriTemplate.expression

  const isMission = !!companyPathExpression &&
    !!mePathExpression &&
    !!configUriTemplateExpression &&
    // @ts-ignore
    (config.uriTemplate.expression === CompanyMission.path.expression || config.uriTemplate.expression === MeMission.path.expression)

  if (config.method === 'get' && isMission) {
    // @ts-ignore
    config.headers['Content-Type'] = 'application/vnd.ctk.carrier_mission.v3+json'

    /**
     * seems necessary to add a null data param when using custom content-type header for GET requests
     * as per https://github.com/axios/axios/issues/3247
     */
    config.data = {}
  }

  return config
}

// @ts-nocheck
import Vue from 'vue'

import { defaultDirection, defaultLoad } from './state'

export default {
  SET_QUOTATION (state, quotation) {
    state.quotation = quotation
  },
  SET_QUOTATION_SELECTED_PRICE (state, price) {
    state.selectedPrice = price
  },
  SET_CURRENT_PRICE (state, price) {
    state.currentPrice = price
  },
  SET_IS_CUSTOM_PRICE_SELECTED (state, isCustomPriceSelected) {
    state.isCustomPriceSelected = isCustomPriceSelected
  },
  SET_QUOTATION_VALIDATED (state, v) {
    state.quotationValidated = v
  },
  SET_ADDRESS (state, { direction, address }) {
    state[direction].address = address
  },
  SET_COMMENT (state, { direction, comment }) {
    state[direction].comment = comment
  },
  SET_MEETING (state, { direction, meeting }) {
    state[direction].meeting = meeting
  },
  SET_CONTACT (state, { direction, contact }) {
    state[direction].contact = contact
  },
  SET_DATE (state, { direction, date }) {
    state[direction].timeslot.date = date
  },
  SET_DATE_FLEXIBLE (state, { direction, flexible }) {
    state[direction].timeslot.flexible = flexible
  },
  SET_TIME_SLOT (state, { direction, startTime, endTime }) {
    state[direction].timeslot.start_time = startTime
    state[direction].timeslot.end_time = endTime
  },
  SET_FETCHED_ADDRESSES (state, { direction, addresses }) {
    state.fetchedAddresses[direction] = addresses
  },
  SET_FETCHED_SHIPMENTS (state, shipments) {
    state.fetchedShipments = shipments
  },
  SET_GUARD (state, { guard, value }) {
    state.guards[guard] = value
  },
  SET_PALLETS (state, pallets) {
    state.pallets = pallets
  },
  ADD_PALLET (state) {
    state.pallets.push({
      quantity: null,
      format: null,
      length: null,
      width: null
    })
  },
  REMOVE_PALLET (state, index) {
    state.pallets.splice(index, 1)
  },
  SET_PALLET_VALUE (state, { index, key, value }) {
    Vue.set(state.pallets[index], key, value)
  },
  SET_GOODS (
    state,
    { type, height, weight, width, length, quantity, format, description, pallets, insuranceType, insuredLoadValue }
  ) {
    state.load.type = type
    state.load.height = height
    state.load.weight = weight
    state.load.width = width
    state.load.length = length
    state.load.quantity = quantity
    state.load.format = format
    state.load.description = description
    state.load.insuranceType = insuranceType
    state.load.insuredLoadValue = insuredLoadValue

    /**
     * Handle case where there is no pallets list available. (eg. from the price quotation form)
     */
    if (!pallets) {
      state.load.pallets = [
        {
          quantity,
          format,
          length,
          width
        }
      ]
    } else {
      state.load.pallets = pallets
    }
  },
  SET_HANDLING_DRIVER (state, { direction, value }) {
    state[direction].handling.driver = value
  },
  SET_HANDLING_TAIL_LIFT (state, { direction, value }) {
    state[direction].handling.tail_lift = value
  },
  SET_PHONE_NUMBER (state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  SET_CUSTOM_PRICE (state, customPrice) {
    state.customPrice = customPrice
  },
  SET_EXPIRATION_DATE (state, date) {
    state.expirationDate = date
  },
  SET_SHIPPER_REFERENCE (state, reference) {
    state.shipperReference = reference
  },
  SET_CONFIRMATION_STEP (state, confirmation) {
    state.isConfirmation = confirmation
  },
  SET_SELECTED_DATE_TYPE (state, type) {
    state.selectedDateType = type
  },
  SET_ADDRESSES_DISTANCE (state, distance) {
    state.addressesDistance = distance
  },
  RESET_FORM (state) {
    state.quotation = null
    state.selectedPrice = null
    state.quotationValidated = false
    state.pickup = JSON.parse(JSON.stringify(defaultDirection))
    state.delivery = JSON.parse(JSON.stringify(defaultDirection))
    state.load = JSON.parse(JSON.stringify(defaultLoad))
    state.addressesDistance = null
    state.isConfirmation = false

    state.fetchedAddresses = {
      pickup: [],
      delivery: []
    }
    state.pallets = [
      {
        quantity: null,
        format: null,
        length: null,
        width: null
      }
    ]

    state.vatNumber = null
    state.phoneNumber = null
    state.expirationDate = null
    state.shipperReference = null

    state.guards = {
      pickup_address: false,
      delivery_address: false,
      dates: false,
      handling: false,
      goods: false,
      quotation: false
    }
  }
}

import Vue from 'vue'
import store from '@/store'
import { AUTHORIZED_PALLET_FORMATS } from '@/composables/constants'

const useDuplicateShipment = (shipment) => {
  const { $matomo: matomo, $moment: moment } = Vue.prototype
  const steps = ['pickup', 'delivery']

  steps.forEach(direction => {
    const { address, comment, contact, time_slot: timeSlot, meeting } = shipment[direction]
    store.dispatch('shipments/new-shipment/setAddress', {
      direction,
      address
    })

    store.dispatch('shipments/new-shipment/setContact', {
      direction,
      contact
    })

    store.dispatch('shipments/new-shipment/setComment', {
      direction,
      comment
    })

    store.dispatch('shipments/new-shipment/setMeeting', {
      direction,
      meeting
    })

    /**
     * Re-parse the start & end time to set the timeslot.
     */
    const defaultStartTime = moment('09:00', 'HH:mm')
    const defaultEndTime = moment('17:00', 'HH:mm')
    const parsedStartTime = moment(timeSlot.start_time, 'HH:mm')
    const parsedEndTime = moment(timeSlot.end_time, 'HH:mm')
    const today = moment().startOf('day')

    let startTime
    let endTime

    if (parsedStartTime && parsedEndTime) {
      if (parsedStartTime.isSame(parsedEndTime)) {
        startTime = defaultStartTime.diff(today, 'minutes')
        endTime = defaultEndTime.diff(today, 'minutes')
      } else {
        startTime = parsedStartTime.diff(today, 'minutes')
        endTime = parsedEndTime.diff(today, 'minutes')
      }

      store.dispatch('shipments/new-shipment/setTimeslot', {
        direction,
        startTime,
        endTime
      })
    }
  })

  /**
   * Set the goods when selecting.
   * A quick workaround here to fix the difference between the load type
   * between a shipment ("pallets") and a quotation request("pallet").
   * TODO: Remove this when the issue will be resolved by the back-end.
   */
  const { quantity, format, length, width, pallets } = shipment.load

  /**
   * In the case where we receive a list of pallets from the backend,
   * consider it as a load with the "pallets" type.
   * This should be removed when we'll be able to send pallets to the backend
   * without going through a transformation from pallets to custom type.
   */
  if (pallets) {
    store.dispatch('shipments/new-shipment/setGoods', {
      ...shipment.load,
      // Override the load type to "pallets" so the form shows the right tab.
      type: 'pallets',
      // Override the insurance type so the form selects the right option
      insuranceType: 'basic'
    })

    // @ts-ignore
    store.dispatch('shipments/new-shipment/setPallets', pallets.map(pallet => {
      /**
       * Since we do not have the format, build it ourselves.
       * Note: this may not match pre-existing formats, so we should fallback to the
       * "other" case that shows the 2 additional fields
       */
      let palletFormat = `${pallet.width}x${pallet.length}`
      if (!Object.keys(AUTHORIZED_PALLET_FORMATS).includes(palletFormat)) {
        palletFormat = 'other'
      }

      return {
        quantity: pallet.quantity,
        format: palletFormat,
        length: pallet.length,
        width: pallet.width
      }
    }))
  } else {
    store.dispatch('shipments/new-shipment/setGoods', {
      ...shipment.load,
      // Override the insurance type so the form selects the right option
      insuranceType: 'basic'
    })
    store.dispatch('shipments/new-shipment/setPallets', [
      {
        quantity,
        format,
        length,
        width
      }
    ])
  }

  // ? Should it be kept as is ? (comes from the newshipmenttemplatelist)
  if (matomo) {
    matomo.trackEvent('Quotations', 'Selected Previous Shipment', shipment.uuid)
  }
}

export default useDuplicateShipment

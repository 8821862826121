
// @ts-nocheck
import { UserInvite, User } from '@/resources'

export default {
  retrieveUserInvites ({ commit, rootGetters }) {
    return UserInvite.get({
      cid: rootGetters['auth/getCid']
    })
      .then(({ data }) => {
        commit('SET_INVITES', data.items)
        commit('SET_INVITES_META', data.meta)
      })
  },
  retrieveUsers ({ commit, rootGetters }) {
    return User.get({
      cid: rootGetters['auth/getCid']
    })
      .then(({ data }) => {
        commit('SET_USERS', data.items)
        commit('SET_USERS_META', data.meta)
      })
  },
  retrieveMoreUsers ({ commit, getters }) {
    if (!getters.hasMoreUsers) return Promise.resolve()

    return User.get({
      cid: getters['auth/getCid']
    }, {
      page: getters.getMeta.page + 1
    })
      .then(({ data }) => {
        commit('ADD_USERS', data.items)
        commit('SET_USERS_META', data.meta)
      })
  },
  retrieveMoreUserInvites ({ commit, getters }) {
    if (!getters.hasMoreUserInvites) return Promise.resolve()

    return UserInvite.get({
      cid: getters['auth/getCid']
    }, {
      page: getters.getMeta.page + 1
    })
      .then(({ data }) => {
        commit('ADD_INVITE', data.items)
        commit('SET_INVITES_META', data.meta)
      })
  },
  requestInvite ({ dispatch, rootGetters }, { email, existingUser }) {
    const payload = {
      email,
      existing_user: existingUser
    }

    return UserInvite.save({
      cid: rootGetters['auth/getCid']
    }, payload)
      .then(res => {
        /**
         * TODO: Replace this re-fetch with a direct add to the invites
         * list once the API will return the newly created invite on the
         * POST response.
         */
        dispatch('retrieveUserInvites')

        /**
         *  * re-fetch user lists as well, as per
         *  * https://app.shortcut.com/ctk/story/4277/etq-owner-je-peux-inviter-ou-supprimer-un-utilisateur-en-%C3%A9tat-pas-d-acc%C3%A8s-dans-la-page-membre-du-bo-front,
         *  * in which no_access users can be invited using the invite flow
        */

        dispatch('retrieveUsers')

        return res
      })
  },
  cancelInvite ({ commit, rootGetters }, uuid) {
    return UserInvite.delete({
      cid: rootGetters['auth/getCid'],
      uid: uuid
    })
      .then(res => {
        commit('REMOVE_INVITE', uuid)

        return res
      })
  },
  resendInvite ({ rootGetters }, uuid) {
    return UserInvite.resend({
      cid: rootGetters['auth/getCid'],
      uid: uuid
    })
  },
  removeMember ({ rootGetters, commit }, uuid) {
    return User.delete({
      cid: rootGetters['auth/getCid'],
      uid: uuid
    })
      .then(res => {
        commit('REMOVE_MEMBER', uuid)

        return res
      })
  }
}

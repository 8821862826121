<template>
  <div class="tw-flex dots-text shipment-item-direction">
    <ctk-address
      :address="address"
      :has-street-name="false"
      class="shipment-item-direction__address dots-text"
    >
      <template #name>
        <span
          class="tw-font-medium shipment-item-direction__address__name dots-text"
        >
          {{ address.name }} -
        </span>
      </template>
    </ctk-address>
    <div
      class="shipment-item-direction__date text-right dots-text"
    >
      <ctk-timeslot-date
        :direction="direction"
        :flexible="timeslot.flexible"
        class="dots-text"
      >
        {{ $moment(timeslot.date).format('LL') }}
      </ctk-timeslot-date>
    </div>
  </div>
</template>

<script>
  import CtkAddress from '@/components/CtkAddress'
  import CtkTimeslotDate from '@/components/CtkTimeslotDate'

  /**
   * @module component - shipmentItemDirection
   * @param {string} direction
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemDirection',
    components: {
      CtkAddress,
      CtkTimeslotDate
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      address () {
        return this.shipment[this.direction].address
      },
      timeslot () {
        // * take the mission time_slots, if exist, instead the ones from the shipment,
        // in case of an accepted bid that modifies the pickup/delivery time slots
        return !!this.shipment.mission && !!this.shipment.mission[this.direction]
          ? this.shipment.mission[this.direction].time_slot
          : this.shipment[this.direction].time_slot
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-direction {
    position: relative;
    flex-wrap: wrap;

    &__address {
      flex: 0.7;

      &__name {
        text-transform: uppercase;
      }
    }

    &__date {
      position: relative;
      padding-left: 30px;
      color: $secondary-text;
      flex: 0.3;
    }

    &.has-link {
      overflow: visible;

      &::after {
        position: absolute;
        content: '';
        background-image: url('~@/assets/img/division-line.svg');
        height: 9px;
        width: 1px;
        left: 9px;
        top: 19px;
      }
    }
  }

</style>

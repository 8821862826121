<template>
  <div
    v-if="content.length"
    class="accessibility-item tw-flex tw-rounded tw-py-3 tw-bg-yellow-300"
  >
    <div class="tw-flex-shrink-0">
      <ui-ctk-icon
        name="warning-full"
        class="tw-w-4 tw-ml-2 tw-mr-1 tw-text-yellow-500"
        data-test="icon"
      />
    </div>
    <div class="tw-flex-1 tw-pr-4">
      <div class="tw-inline-flex tw-flex-wrap tw-items-center">
        <div
          v-text="title"
          class="tw-uppercase tw-font-bold tw-text-yellow-700 tw-text-xs tw-mr-1"
          data-test="title"
        />
      </div>
      <p
        v-for="(accessibility, k) in content"
        :key="k"
        v-text="accessibility"
        class="tw-break-words tw-whitespace-pre-wrap tw-text-sm tw-mb-0 tw-text-yellow-700"
        data-test="body"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'AccessibilityItem',
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: Array,
        required: true
      }
    }
  })
</script>

import store from '@/store'
import { Auth } from '@/resources'
import Config from '@/services/Config'
import duplicate from '@/router/helpers/shipments/duplicate'
import createExternalRequestShipment from '@/router/helpers/external-requests/create-external-request-shipment'

/**
 * Check for impersonate query param
 */
export default async (to, from, next) => {
  if (to.name === 'Impersonate' || to.name === 'DuplicateShipment' || to.name === 'ExternalRequestShipment') {
    const { username, code, impersonate, return_url: returnUrl } = to.query

    if (username && code && impersonate && returnUrl) {
      const formData = new URLSearchParams()
      formData.append('grant_type', 'authorization_code')
      formData.append('client_id', Config.get('api.clientImpersonateId'))
      formData.append('username', username)
      formData.append('authorization_code', code)

      store.dispatch('auth/setImpersonate', null)
      store.dispatch('auth/setImpersonating', false)

      try {
        const tokenRes = await Auth.signIn({}, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        const { token } = tokenRes.data

        if (!token) {
          return next({ name: 'SignIn' })
        }

        store.dispatch('auth/setToken', token)
        store.dispatch('auth/setImpersonate', {
          username: impersonate
        })
        store.dispatch('auth/setImpersonating', true)
        store.dispatch('auth/setRedirectUrl', returnUrl)

        if (to.name === 'Impersonate') {
          next({ name: 'Dashboard' })
        } else if (to.name === 'DuplicateShipment') {
          await duplicate(to, from, next)
        } else if (to.name === 'ExternalRequestShipment') {
          createExternalRequestShipment(to, from, next)
        }
      } catch (err) {
        return next({ name: 'SignIn' })
      }
    } else {
      return next({ name: 'SignIn' })
    }
  } else {
    next()
  }
}

<template>
  <portal to="dialogs">
    <transition
      :name="transitionName"
    >
      <div
        v-if="modalState"
        :class="$attrs['modal-class']"
        class="ctk-dialog tw-fixed tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-flex tw-w-full tw-h-full tw-overflow-y-auto"
        data-test="mask"
      >
        <div class="modal-wrapper tw-flex tw-flex-col tw-w-full tw-p-4 tw-justify-center tw-items-center tw-max-h-full tw-align-middle tw-min-h-full">
          <div
            v-click-outside="clickOutside"
            :style="{
              maxWidth
            }"
            class="modal-container tw-bg-white tw-mx-auto tw-my-0 tw-rounded tw-shadow-xl tw-max-h-full tw-w-full tw-transform tw-overflow-y-auto"
            :class="{ 'modal-container--has-bottom-ribbon': bottomRibbon }"
            data-test="container"
          >
            <div
              v-if="!hideHeader"
              class="ctk-dialog__header modal-header tw-flex tw-items-start tw-justify-between tw-p-4 tw-rounded-tl tw-rounded-tr tw-flex tw-justify-between tw-items-center tw-rounded-tl tw-rounded-tr tw-border-none"
              :class="{
                'tw-bg-secondary': !transparentHeader,
                'modal-header--transparent': transparentHeader,
              }"
              data-test="header"
            >
              <slot name="modal-header">
                <h5 class="tw-font-normal tw-m-0 tw-w-full tw-text-white tw-text-xl">
                  <slot name="title" />
                </h5>

                <!-- Close button -->
                <transition name="fade">
                  <ui-button
                    v-if="hasClose"
                    :title="$t('close') | capitalize"
                    :variant="transparentHeader ? 'light' : 'primary'"
                    class="ctk-dialog__header__close tw-flex-shrink-0 close-modal !tw-bg-transparent"
                    data-test="close"
                    @click="close"
                  >
                    <template #left-icon>
                      <ui-ctk-icon
                        name="close"
                        data-test="icon"
                      />
                    </template>
                  </ui-button>
                </transition>
              </slot>
            </div>
            <div
              :style="{
                backgroundColor: bgColor
              }"
              class="modal-body p-0"
            >
              <slot />
            </div>
            <div
              v-if="!hideFooter"
              class="modal-footer"
              data-test="footer"
            >
              <slot name="footer">
                <ui-button
                  type="button"
                  variant="primary"
                  @click="close"
                >
                  {{ $t('close') | capitalize }}
                </ui-button>
              </slot>
            </div>

            <div
              v-if="bottomRibbon"
              class="modal-bottom-ribbon"
            />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
  import { defineComponent, toRefs } from '@vue/composition-api'
  import { directive } from 'v-click-outside'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'
  import UiButton from '@/components/UI/Button/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import { EventBus } from '@/services/EventBus'

  export default defineComponent({
    name: 'CtkDialog',
    components: {
      UiCtkIcon,
      UiButton
    },
    directives: {
      clickOutside: directive
    },
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'input'
    },
    emits: [
      'input',
      'handle-close'
    ],
    props: {
      value: {
        type: Boolean,
        default: true
      },
      maxWidth: {
        type: String,
        default: '500px'
      },
      bgColor: {
        type: String,
        default: null
      },
      persistent: {
        type: Boolean,
        default: false
      },
      hasClose: {
        type: Boolean,
        default: true
      },
      transitionName: {
        type: String,
        default: 'modal'
      },
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideFooter: {
        type: Boolean,
        default: false
      },
      addCloseModalEvent: {
        type: Boolean,
        default: false
      },
      transparentHeader: {
        type: Boolean,
        default: false
      },
      bottomRibbon: {
        type: Boolean,
        default: false
      }
    },
    setup (props, { emit }) {
      const { persistent, addCloseModalEvent } = toRefs(props)

      const { state: modalState } = useModelGetterSetter(props, 'value')

      function close () {
        modalState.value = false
        if (addCloseModalEvent.value) EventBus.$emit('add-close-modal-event')

        // TODO: Is this necessary?
        emit('handle-close')
      }

      function clickOutside () {
        if (persistent.value) return
        if (addCloseModalEvent.value) EventBus.$emit('add-close-modal-event')
        close()
      }

      return {
        close,
        clickOutside,
        modalState
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-dialog {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 34, 46, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.7;
  top: 55px;
  height: 100%;
  height: calc(100% - 55px);
  z-index: 1050;
  transition: background-color 300ms ease;
  will-change: background-color;
}
@media (min-width: 770px) {
  .ctk-dialog {
    top: 0 !important;
    height: 100% !important;
  }
}
.ctk-dialog .modal-container {
  transition: transform 300ms ease, opacity 300ms ease;
  will-change: transform, opacity;
}
.modal-enter, .modal-leave-active {
  --tw-bg-opacity: 0;
}
.modal-enter .modal-container, .modal-leave-active .modal-container {
  opacity: 0;
  --tw-translate-y: -8rem;
}
.modal-container {
  position: relative;
}
.modal-container--has-bottom-ribbon {
  padding-bottom: 56px;
}
.modal-bottom-ribbon {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

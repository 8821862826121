<template>
  <div class="shipment-load-summary tw-truncate">
    <div class="tw-flex tw-truncate">
      <div
        v-if="load.type === 'pallets' && this.pallets"
        class="tw-flex tw-flex-col tw-truncate"
      >
        <div
          v-for="(pallet, k) in formattedPallets"
          :key="k"
          class="tw-font-medium tw-truncate"
          v-text="pallet"
        />
      </div>
      <template
        v-else
      >
        <span
          v-text="load.type === 'pallets'
            ? $tc('app.values.load_summary.pallet', load.quantity, {
              count: $n(load.quantity)
            })
            : $t(`app.labels.load_summary.${load.type}`)"
          data-test="load-type"
        />
        <span
          v-html="'&nbsp;'"
        />
        <i18n
          path="app.values.load_summary.format"
          data-test="format"
          tag="span"
        >
          <template #format>
            <span
              class="tw-font-medium"
              data-test="value"
              v-text="format"
            />
          </template>
        </i18n>
      </template>
    </div>
    <div>
      <i18n
        path="app.values.load_summary.height"
        data-test="height"
        tag="span"
      >
        <template #height>
          <span
            class="tw-font-medium"
            data-test="value"
            v-text="$n(load.height)"
          />
        </template>
      </i18n>&nbsp;-&nbsp;<i18n
        path="app.values.load_summary.weight"
        data-test="weight"
        tag="span"
      >
        <template #weight>
          <span
            class="tw-font-medium"
            data-test="value"
            v-text="$n(load.weight)"
          />
        </template>
      </i18n>
    </div>
    <div
      v-if="!!load.insuranceType"
      v-text="formattedAdValoremInsurance"
      class="tw-font-medium"
    />
  </div>
</template>

<script>
  /**
   * Shows a formatted version of the load with the pallets detail
   * @module component - ShipmentLoadSummary
   * @param {object} load
   */
  export default {
    name: 'ShipmentLoadSummary',
    props: {
      load: {
        type: Object,
        required: true
      },
      pallets: {
        type: Array,
        default: null
      }
    },
    computed: {
      formattedPallets () {
        return this.pallets
          .map(({ quantity, format, width, length }) => {
            const quantityValue = this.$tc('app.values.load_summary.pallet', quantity, {
              count: this.$n(quantity)
            })
            const formatValue = this.$t('app.values.load_summary.format', {
              format: !!width && !!length ? `${width}x${length}` : format
            })

            return `${quantityValue} ${formatValue}`
          })
      },
      /**
       * Returns the formatted version of the width / length
       * @function format
       * @returns {string}
       */
      format () {
        const { type, format } = this.load
        let width, length

        if (type === 'pallets') {
          const [formatWidth, formatLength] = format.split('x')
          width = formatWidth
          length = formatLength
        } else {
          width = this.load.width
          length = this.load.length
        }

        return `${width}x${length}`
      },
      formattedAdValoremInsurance () {
        const { insuranceType, insuredLoadValue } = this.load

        if (!insuranceType) {
          return null
        }

        return insuranceType === 'basic'
          ? this.$t('app.values.load_summary.insurance.basic')
          : this.$t('app.values.load_summary.insurance.premium', {
            amount: this.$options.filters.currency(insuredLoadValue, 'EUR', this.$i18n.locale, true)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-load-summary {
    color: $primary-text;
    line-height: 1.5em;

    &__label {
      font-size: 13px;
    }
  }

</style>

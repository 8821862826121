import { Shipment } from '@/resources'
import useDuplicateShipment from '@/composables/shipments/useDuplicateShipment'

const duplicate = async (to, from, next) => {
  try {
    const { company_uuid: companyUuid } = to.query
    const { uuid: shipmentUuid } = to.params

    const { data: shipment } = await Shipment.get({
      cid: companyUuid,
      sid: shipmentUuid
    })

    if (shipment) {
      useDuplicateShipment(shipment)

      next({
        name: 'NewShipmentAddress',
        params: {
          direction: 'pickup'
        }
      })
    } else {
      return next({ name: 'SignIn' })
    }
  } catch (error) {
    if (error) {
      console.log(error)
    }

    return next({ name: 'SignIn' })
  }
}

export default duplicate

<template>
  <ctk-input-select
    v-model="country"
    :items="countriesSorted"
    :error="error"
    :hint="hint"
    :disabled="disabled"
    :full-width="fullWidth"
    :label="hint || label"
    :required="isRequired"
    ignore-optional
    class="country-selector"
  >
    <template
      #currentItem="{ item }"
    >
      <div
        v-if="item"
        class="tw-flex tw-items-center"
      >
        <ui-flag
          :country="item.value"
          data-test="flag"
        />
        <div
          class="country-selector__value tw-truncate"
          data-test="value"
          v-text="hasName
            ? item.text
            : callingCode"
        />
      </div>
    </template>

    <template
      #item="{ item }"
    >
      <div class="tw-flex tw-items-center">
        <ui-flag
          :country="item.value"
          data-test="flag"
        />
        <div class="tw-truncate">
          {{ item.text }}
        </div>
      </div>
    </template>
  </ctk-input-select>
</template>

<script>
  import { getCountryCallingCode } from 'libphonenumber-js'
  import { defineComponent } from '@vue/composition-api'

  import useCountryLists from '@/composables/useCountryLists'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'

  export default defineComponent({
    name: 'CountrySelector',
    components: {
      CtkInputSelect
    },
    props: {
      value: { type: [String, Object], required: false, default: null },
      label: { type: String, default: 'Choose country' },
      hint: { type: [String, Boolean], default: String },
      error: { type: Boolean, default: Boolean },
      disabled: { type: Boolean, default: false },
      fullWidth: { type: Boolean, default: true },
      items: { type: Array, default: Array, required: true },
      preferredCountries: { type: Boolean, default: false },
      onlyCountries: { type: Array, default: null },
      ignoredCountries: { type: Array, default: Array },
      hasName: {
        type: Boolean,
        default: false
      },
      loader: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: country } = useModelGetterSetter(props, 'value')

      return {
        country
      }
    },
    computed: {
      /**
       * Returns a list of the preferred countries if requested.
       * @returns {Array<string>}
       */
      preferredCountriesList () {
        const { getAvailableCountries } = useCountryLists()
        const availableCountries = getAvailableCountries()

        return this.preferredCountries
          ? availableCountries.map(country => country['iso-2'])
          : []
      },
      /**
       * Returns true if the field is required
       * @function isRequired
       * @returns {boolean}
       */
      isRequired () {
        return typeof this.$attrs.required !== 'undefined' && this.$attrs.required !== false
      },
      countriesList () {
        return this.items.filter(item => !this.ignoredCountries.includes(item.iso2))
      },
      countriesFiltered () {
        const countries = this.onlyCountries || this.preferredCountriesList
        return this.countriesList.filter(item => countries.find(country => item.iso2.includes(country)))
      },
      otherCountries () {
        return this.countriesList.filter(item => !this.preferredCountriesList.includes(item.iso2))
      },
      countriesSorted () {
        const countries = this.preferredCountriesList
          ? [...this.countriesFiltered,
             ...this.otherCountries]
          : this.onlyCountries
            ? this.countriesFiltered
            : this.countriesList

        return countries.map(country => ({
          value: country.iso2,
          text: country.name
        }))
      },
      callingCode () {
        return this.value ? `+${getCountryCallingCode(this.value)}` : null
      }
    },
    mounted () {
      this.$parent.$on('phone-number-focused', () => { this.isFocus = false })
    }
  })
</script>

<style lang="scss" scoped>

  .country-selector {
    position: relative;

    &__value {
      @media only screen and (max-width: $breakpoint-mobile-l) {
        font-size: 1rem;
      }
    }
  }

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-notifications-dialkog-item-footer tw-flex tw-items-center"},[_c('ui-ctk-icon',{staticClass:"tw-text-gray-600 tw-text-5xl",attrs:{"name":"expiration"}}),_c('p',{staticClass:"tw-text-gray-700 tw-mb-0"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.shipment_expiring_soon'))}}),_c('UiLink',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        click: {
          category: 'Global',
          action: 'Notifications',
          name: 'Expiration Delay Clicked'
        }
      }),expression:"{\n        click: {\n          category: 'Global',\n          action: 'Notifications',\n          name: 'Expiration Delay Clicked'\n        }\n      }"}],staticClass:"tw-ml-2",attrs:{"to":{
        name: 'Shipment',
        params: {
          uuid: _vm.shipment.uuid,
        },
        query: {
          'update-expires-at': 1
        }
      },"standalone":""},domProps:{"textContent":_vm._s(_vm.$t('app.buttons.postpone_expires_at'))},nativeOn:{"click":function($event){return _vm.$emit('click')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <transition name="fade">
    <aside
      v-if="value"
      class="ctk-search-dialog"
      role="dialog"
      aria-describedby="modal-title"
    >
      <div class="ctk-search-dialog__content tw-flex">
        <div class="tw-flex tw-flex-col ctk-search-dialog__content__hero">
          <div class="ctk-search-dialog__content__hero__content px-3 pt-3">
            <picture
              class="ctk-search-dialog__content__hero__content__logo"
            >
              <source
                srcset="@/assets/img/logo-icon-white.webp"
                type="image/webp"
              >
              <img
                class="ctk-search-dialog__content__hero__content__logo"
                src="@/assets/img/logo-icon-white.png"
                width="50"
                height="50"
                alt=""
                data-test="logo"
              >
            </picture>

            <h1
              class="tw-font-light"
              data-test="title"
              id="modal-title"
            >
              {{ appTitleSearch }}
            </h1>
            <p
              class="fs-14"
              data-test="explanation"
            >
              {{ appParagraphSearch }}
            </p>
          </div>
        </div>
        <div
          :class="{
            'has-illustration': !getSearchMeta || getSearchMeta.item_count === 0,
            'no-results': getSearchMeta && getSearchMeta.item_count === 0
          }"
          class="ctk-search-dialog__content__form tw-flex tw-flex-col"
        >
          <ValidationObserver
            ref="observer"
          >
            <template slot-scope="{ invalid }">
              <form
                @submit.prevent="submitted"
                :disabled="invalid"
              >
                <ValidationProvider
                  rules="required|min:3"
                  :name="$t('app.fields.search')"
                  slim
                >
                  <template slot-scope="{ errors, invalid: fieldInvalid, validated }">
                    <ctk-input-text
                      ref="search"
                      id="search"
                      v-model="query"
                      :label="inputLabel"
                      :loader="$wait.is('fetching shipments search*')"
                      :error="fieldInvalid && validated"
                      :hint="fieldInvalid ? errors[0] : null"
                      data-test="search"
                      type="search"
                      name="search"
                      clearable
                      required
                      @input="lazySearch"
                      @clear="reset"
                    />
                  </template>
                </ValidationProvider>
              </form>
            </template>
          </ValidationObserver>

          <div
            v-if="getSearchMeta"
            v-infinite-scroll="loadMore"
            class="ctk-search-dialog__content__form__results tw-flex tw-flex-1 tw-flex-col"
          >
            <template
              v-if="getSearchMeta.item_count"
            >
              <h2
                data-test="result-title"
                class="ctk-search-dialog__content__form__results__title tw-font-normal"
              >
                {{ appTitleSearchResult }}
              </h2>

              <shipment-search-item
                v-for="shipment in getSearchItems"
                :key="shipment.uuid"
                :route-name="routeName"
                :shipment="shipment"
                class="mb-3"
                @click.native="close"
              />

              <ctk-infinite-loader
                data-test="loader"
                :load-more-text="$t('app.buttons.load_more_searches')"
                :loaded-text="loadedText"
                :is-loading="$wait.is('fetching more shipments search')"
                :can-load-more="canLoadMore"
                :items-count="getSearchMeta.item_count"
                @load-more="loadMore"
              />
            </template>
            <template
              v-else
            >
              <p
                class="ctk-search-dialog__content__form__results__not-found tw-text-center"
                data-test="not-found"
              >
                {{ notFoundMessage }}
              </p>
            </template>
          </div>
        </div>
      </div>

      <button
        class="tw-flex btn ctk-search-dialog__close"
        data-test="close"
        type="button"
        :title="$t('close') | capitalize"
        @click="close"
      >
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </button>
    </aside>
  </transition>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'
  import { debounce } from 'underscore'
  import * as Sentry from '@sentry/browser'

  import Dialog from '@/mixins/dialog'
  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader/index.vue'
  import ShipmentSearchItem from '@/views/Common/Search/ShipmentSearchItem/index.vue'
  import { showToaster } from '@/services/Toaster'

  /**
   * @module component - CtkSearchDialog
   */
  export default defineComponent({
    name: 'CtkSearchDialog',
    components: {
      UiCtkIcon,
      CtkInputText,
      CtkInfiniteLoader,
      ShipmentSearchItem
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      source: {
        type: [HTMLElement],
        default: null
      }
    },
    mixins: [
      Dialog
    ],
    data () {
      return {
        /** @type {string|null} */
        query: null,
        /** @type {string|null} */
        searchedQuery: null,
        results: null,
        /** @type {{ cancelTokenSource: import('axios').CancelTokenSource, request: any }|null} */
        previousRequest: null
      }
    },
    computed: {
      ...mapGetters([
        'getSearchItems',
        'getSearchMeta',
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * @function canLoadMore
       * @returns {boolean|undefined}
       */
      canLoadMore () {
        const meta = this.getSearchMeta
        if (!meta || (meta && !meta.pagination)) return
        return meta.pagination.current_page + 1 <= meta.pagination.page_count
      },
      routeName () {
        return this.isUserShipper ? 'Shipment' : 'Mission'
      },
      searchEntity () {
        return this.isUserShipper ? 'shipment' : 'mission'
      },
      appTitleSearch () {
        return this.$t(`app.titles.search.${this.searchEntity}`)
      },
      appParagraphSearch () {
        return this.$t(`app.paragraphs.search.${this.searchEntity}`)
      },
      inputLabel () {
        return this.$t(`app.labels.search.${this.searchEntity}`)
      },
      appTitleSearchResult () {
        return this.getSearchMeta && this.getSearchMeta.item_count ? this.$tc(`app.titles.search_results.${this.searchEntity}`, this.getSearchMeta.item_count, {
          count: this.getSearchMeta.item_count
        }) : null
      },
      loadedText () {
        return this.$t(`app.paragraphs.searches_loaded.${this.searchEntity}`, {
          query: this.searchedQuery
        })
      },
      notFoundMessage () {
        return this.$t(`app.paragraphs.search_${this.searchEntity}_not_found`, {
          query: this.searchedQuery
        })
      }
    },
    watch: {
      value: function (v) {
        if (v) {
          // Mount the dialog mixin bindings
          this.mount()

          // this.$validator.resume()
          this.$nextTick(() => {
            const { search } = this.$refs
            if (!search) return
            // @ts-ignore
            search.$el.querySelector('input').focus()
          })
        } else {
          // Unmount the dialog mixin bindings
          this.unmount()
          // this.$validator.pause()
        }
      }
    },
    methods: {
      ...mapActions([
        'setSearchItems',
        'setSearchMeta',
        'retrieveSearches',
        'retrieveMoreSearches'
      ]),
      submitted () {
        if (this.query !== null && this.query.length === 0) {
          this.reset()
        }

        if (!this.query) {
          return false
        }

        // @ts-ignore
        return this.$refs.observer.validate()
          .then(async (/** @type {boolean} **/ valid) => {
            const searchId = `${Date.now()}${this.query}`
            if (!valid) return false

            /**
             * Abort the pending request, if any before sending another one
             */
            if (this.previousRequest) {
              try {
                this.previousRequest.cancelTokenSource.cancel()
              } catch (e) {
                console.error('Could not abort the request', e)
              }
            }

            this.$wait.start(`fetching shipments search ${searchId}`)
            const { cancelTokenSource, request } = await this.retrieveSearches({
              query: this.query
            })
            this.previousRequest = { cancelTokenSource, request }

            return request
              // @ts-ignore
              .then(res => {
                this.searchedQuery = this.query

                try {
                  if (this.$matomo && this.query) {
                    this.$matomo.trackEvent('Shipments', 'Searched', this.query, res.data.meta.item_count)
                  }
                } catch (e) {
                  console.error('Error occured while tagging Matomo', e)
                }

                return res
              })
              // @ts-ignore
              .catch(err => {
                if (!axios.isCancel(err)) {
                  showToaster(this, this.$t('an_error_has_occurred'), { type: 'error' })

                  Sentry.captureException(
                    new Error('Error occured while searching a shipment'),
                    {
                      extra: {
                        err,
                        data: {
                          cancelTokenSource,
                          request,
                          query: this.query
                        }
                      }
                    }
                  )
                }
              })
              .finally(() => {
                this.$wait.end(`fetching shipments search ${searchId}`)
              })
          })
      },
      async loadMore () {
        if (!this.canLoadMore || this.$wait.is('fetching more shipments search')) return

        this.$wait.start('fetching more shipments search')
        const { request } = await this.retrieveMoreSearches({
          query: this.query
        })

        request
          .then(() => {
            this.searchedQuery = this.query
          })
          // @ts-ignore
          .catch(err => {
            if (!axios.isCancel(err)) {
              showToaster(this, this.$t('an_error_has_occurred'), { type: 'error' })
              console.error('Error occured while searching a shipment', err)
            }
          })
          .finally(() => this.$wait.end('fetching more shipments search'))
      },
      lazySearch: debounce(
        function () {
          // @ts-ignore
          this.submitted()
        },
        400
      ),
      /**
       * Resets the search
       * @function reset
       */
      reset () {
        this.searchedQuery = null
        this.query = null
        this.setSearchItems([])
        this.setSearchMeta(null)
      },
      /**
       * Closes the dialog
       * @function close
       */
      close () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Closed Search')
        }

        if (this.source) this.source.focus()
        this.reset()
        this.$emit('input', false)
      }
    },
    beforeDestroy () {
      this.unmount()
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-search-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(#303144, 0.7);

    &__content {
      &__hero {
        position: relative;
        height: 100vh;
        width: 380px;
        background-image: url('~@/assets/img/backgrounds/search.jpg');
        background-size: cover;
        background-position: center;

        &::before {
          position: absolute;
          content: '';
          background-color: rgba(black, 0.7);
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        &__content {
          margin: auto;
          color: white;
          text-align: center;
          z-index: 1;

          &__logo {
            margin-bottom: 32px;
          }

          h1 {
            font-size: 26px;
            margin-bottom: 24px;
          }
        }
      }

      &__form {
        position: relative;
        overflow-y: auto;
        background-color: white;
        width: 500px;
        padding: 84px 30px 0 30px;

        &.has-illustration::after {
          content: '';
          position: absolute;
          bottom: 90px;
          right: 0;
          background-repeat: no-repeat;
          background-image: url('~@/assets/img/illustrations/proposal-refuse.svg');
          background-size: cover;
          background-position: center;
          width: 400px;
          height: 230px;
          opacity: 0.8;

          @media only screen and (max-height: 680px) {
            display: none;
          }
        }

        &__results {
          overflow-y: auto;

          &__title,
          &__not-found {
            font-size: 20px;
          }

          &__title {
            position: relative;
            font-size: 20px;
            color: $primary-text;
            margin: 40px 0;

            &::after {
              content: '';
              position: absolute;
              background-color: $divider;
              width: 50%;
              height: 1px;
              bottom: -8px;
              left: 0;
            }
          }

          &__not-found {
            margin: auto;
            color: $secondary-text;
            padding-bottom: 90px;
          }
        }

        @media only screen and (max-width: $breakpoint-mobile-l) {
          padding: 84px 16px 0 16px;
        }
      }

      @media only screen and (max-width: 1225px) {
        flex-direction: column;
        height: 100%;

        &__hero {
          height: auto;
          min-height: 250px;
          width: 500px;
        }

        &__form {
          padding-top: 32px;
          flex: 1;
        }

        &__form.has-illustration.no-results {
          &::after {
            display: none;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        &__hero,
        &__form {
          width: 100%;
        }
      }
    }

    &__close {
      position: absolute;
      top: 60px;
      right: 60px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      color: white;
      background-color: $secondary;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);

      &:hover {
        background-color: #303144;
      }

      &:focus {
        @include focusShadow($secondary);
      }

      .ctk-font {
        margin: auto;
      }

      @media only screen and (max-width: 636px) {
        padding: 0;
        width: 35px;
        height: 35px;
        right: 16px;
        top: 16px;
        color: #303144;
        background-color: white;
        z-index: 101;

        .ctk-font {
          font-size: 24px;
        }
      }
    }
  }

</style>

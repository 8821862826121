// @ts-ignore
import { Me } from '@/resources'

/**
 * @type {{
 *   state: () => any
 *   getters: {[key: string]: (state: any) => any},
 *   actions: {[key: string]: (context: any, payload: any) => any},
 *   mutations: {[key: string]: (state: any, payload: any) => void},
 * }}
 */
const VuexModule = {
  namespaced: true,
  state () {
    return {
      interval: null,
      requiresUpdate: false
    }
  },
  getters: {
    getInterval: state => state.interval,
    isUpdateRequired: state => state.requiresUpdate
  },
  actions: {
    setInterval: ({ commit }, interval) => commit('SET_INTERVAL', interval),
    setRequiresUpdate: ({ commit }, requires) => {
      commit('SET_REQUIRES_UPDATE', requires)
    },
    setUserInfos: ({ dispatch }, info) => {
      dispatch('auth/setUserInfos', info, { root: true })
    }
  },
  mutations: {
    'SET_INTERVAL' (state, interval) {
      state.interval = interval
    },
    'SET_REQUIRES_UPDATE' (state, requires) {
      state.requiresUpdate = requires
    }
  }
}

class VueUpdateCgu {
  /**
   * @constructor
   * @param {{ vue: import('vue').VueConstructor, store: import('vuex').Store<typeof VuexModule.state> }} options
   */
  constructor ({ store }) {
    this.store = store

    this.store.registerModule(['cgu'], VuexModule)

    if (!store.getters['cgu/getInterval']) {
      const updateInterval = setInterval(async () => {
        const hasUserInfos = !!this.store.state.auth.userInfos
        const impersonate = !!this.store.state.auth.impersonate
        if (hasUserInfos && !impersonate) {
          Me.get()
            .then(({ status, data }) => {
              if (status === 200) {
                store.dispatch('cgu/setUserInfos', data)
                if (data.terms_of_service && data.terms_of_service.acceptation_required === true) {
                  store.dispatch('cgu/setRequiresUpdate', true)
                }
              }
            })
            .catch(() => {})
        }
      }, 30000)

      store.dispatch('cgu/setInterval', updateInterval)
    }
  }
}

export default {
  /**
   * @function install
   * @param {import('vue').VueConstructor} Vue
   */
  install (Vue) {
    Vue.mixin({
      beforeCreate () {
        const { store, parent } = this.$options
        const storeToUse = store || (parent && parent.$store)

        if (!storeToUse) { return }
        if (Vue.prototype.$updateCgu) { return }

        const updateCgu = new VueUpdateCgu({
          vue: Vue,
          store: storeToUse
        })

        Vue.prototype.$updateCgu = updateCgu
      }
    })
  }
}

<template>
  <ctk-aside-dialog
    v-model="modalState"
    :title="$options.filters.capitalize($t('your_proposal'))"
    :modal-class="`dialog-proposal dialog-proposal--step-${step}`"
  >
    <template #aside>
      <div>
        <offer-summary-card
          :title="$t('proposal_form.initial_offer')"
          :offer="getCurrentOffer"
          class="tw-mb-5"
          data-test="summary"
        />

        <new-shipment-acceptation-dialog-reassurance
          :icon="require('@/assets/img/icons/quotation/contract.svg')"
          :title="$t('app.labels.engagement')"
          :content="$t('offers.paragraphs.proposal.engagement')"
          data-test="reassurance"
        />
      </div>
    </template>

    <div
      class="tw-relative tw-flex tw-flex-col tw-p-4 md:tw-px-10 md:tw-pb-10 md:tw-pt-28 tw-h-full tw-overflow-y-auto"
    >
      <summary-offer
        v-if="hasOptions && step === 2"
        :offer="getCurrentOffer"
        :button="$t('proposal_form.submit_button')"
        :content="$t('offer.proposal_dialog.summary_offer.content')"
        has-back-button
        @next="saveProposal"
        @back="step = 1"
        data-test="summary-offer"
      />

      <div
        class="tw-flex tw-flex-col tw-h-full tw-flex-1"
        v-else-if="step === 0 || step === 1"
      >
        <!-- Price and dates step -->
        <ValidationObserver
          v-if="step === 0"
          ref="priceDatesObserver"
          tag="div"
          class="tw-flex-1"
          data-test="price-dates-step"
        >
          <!-- Price -->
          <div
            class="tw-mb-8 md:tw-mb-16"
            data-test="price-section"
          >
            <div
              class="tw-flex tw-items-center tw-mb-3"
              data-test="header"
            >
              <ui-ctk-icon
                class="tw-text-blue-500 tw--ml-2"
                name="budget"
                data-test="icon"
              />
              <div
                id="proposal-different-price"
                v-text="$t('offers.titles.different_price')"
                class="tw-text-secondary tw-text-base"
                data-test="title"
              />
            </div>
            <div
              data-test="content"
              class="tw-pl-7"
            >
              <ui-fat-radio-group
                v-model="hasPriceChange"
                :items="[
                  {
                    title: $i18n.t('yes'),
                    value: true
                  },
                  {
                    title: $i18n.t('no'),
                    value: false
                  }
                ]"
                aria-labelledby="proposal-different-price"
                class="dialog-proposal__radio tw-flex tw-mb-5"
                data-test="radio"
              >
                <template slot-scope="{ item, active, keydown }">
                  <ui-fat-radio-item
                    :title="item.title"
                    :active="active"
                    @keydown.native="keydown"
                    @click.native="hasPriceChange = item.value"
                  >
                    <div
                      v-text="item.title"
                    />
                  </ui-fat-radio-item>
                </template>
              </ui-fat-radio-group>

              <div
                v-if="hasPriceChange"
                data-test="price-field"
              >
                <ValidationProvider
                  ref="price-provider"
                  :name="$t('app.labels.price')"
                  rules="required"
                >
                  <template slot-scope="{ errors, invalid, validated }">
                    <ctk-input-text
                      id="priceInput"
                      v-model.number="formData.price"
                      :hint="errors[0]"
                      :error="invalid && validated || errors && errors.length > 0"
                      :label="$t('proposal_form.price.label') | capitalize"
                      type="number"
                      min="0"
                      required
                    />
                  </template>
                </ValidationProvider>

                <div
                  v-if="hasProposalWarning && proposalWarning"
                  :class="[`proposal-warning--${proposalWarning.key}`]"
                  class="tw-relative tw-flex tw-pl-3 tw-text-sm tw-font-medium proposal-warning tw-mt-1"
                  data-test="proposal-warning"
                  v-text="$t(proposalWarning.message)"
                />
              </div>
            </div>
          </div>

          <!-- Dates -->
          <div
            data-test="dates-section"
          >
            <div
              class="tw-flex tw-items-center tw-mb-3"
              data-test="header"
            >
              <ui-ctk-icon
                class="tw-text-blue-500 tw--ml-2"
                name="calendar"
                data-test="icon"
              />
              <div
                id="proposal-different-dates"
                v-text="$t('offers.titles.different_dates')"
                class="tw-text-secondary tw-text-base"
                data-test="title"
              />
            </div>
            <div
              data-test="content"
              class="tw-pl-7"
            >
              <ui-fat-radio-group
                v-model="hasDatesChange"
                :items="[
                  {
                    title: $i18n.t('yes'),
                    value: true
                  },
                  {
                    title: $i18n.t('no'),
                    value: false
                  }
                ]"
                aria-labelledby="proposal-different-dates"
                class="dialog-proposal__radio tw-flex tw-mb-5"
                data-test="radio"
              >
                <template slot-scope="{ item, active, keydown }">
                  <ui-fat-radio-item
                    :title="item.title"
                    :active="active"
                    @keydown.native="keydown"
                    @click.native="hasDatesChange = item.value"
                  >
                    <div
                      v-text="item.title"
                    />
                  </ui-fat-radio-item>
                </template>
              </ui-fat-radio-group>

              <div
                v-if="hasDatesChange"
                class="tw-flex tw-flex-col"
                data-test="dates-field"
              >
                <div
                  class="tw-flex-1 tw-mb-4"
                >
                  <ValidationProvider
                    :ref="'pickup_date-provider'"
                    slim
                  >
                    <template slot-scope="{ invalid, errors, clear, validated }">
                      <ctk-date-time-picker
                        :id="'pickup-date'"
                        v-model="formData.dates.pickup"
                        :label="$t('pickup_date') | capitalize"
                        :locale="$i18n.locale"
                        :min-date="allowedDates.pickup"
                        :data-test="'pickup-date'"
                        :hint="errors[0]"
                        :error="invalid && validated || errors && errors.length > 0"
                        color="#287696"
                        format="YYYY-MM-DD"
                        formatted="ddd LL"
                        no-button
                        only-date
                        auto-close
                        position="top"
                        @input="clear"
                      />
                    </template>
                  </ValidationProvider>
                </div>
                <div
                  class="tw-flex-1"
                >
                  <ValidationProvider
                    ref="delivery_date-provider"
                    slim
                  >
                    <template slot-scope="{ invalid, errors, clear, validated }">
                      <ctk-date-time-picker
                        :id="'delivery-date'"
                        v-model="formData.dates.delivery"
                        :label="$t('delivery_date') | capitalize"
                        :locale="$i18n.locale"
                        :min-date="allowedDates.delivery"
                        :data-test="'delivery-date'"
                        :hint="errors[0]"
                        :error="invalid && validated || errors && errors.length > 0"
                        color="#287696"
                        format="YYYY-MM-DD"
                        formatted="ddd LL"
                        no-button
                        only-date
                        auto-close
                        position="top"
                        @input="clear"
                      />
                    </template>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <!-- Error paragraph when the user selects no on both price and dates -->
          <p
            v-if="hasDatesChange === false && hasPriceChange === false"
            v-text="$t('offers.paragraphs.proposal.select_price_dates')"
            class="tw-text-gray-800 tw-bg-gray-200 tw-px-4 tw-py-3 tw-rounded tw-mt-8"
            data-test="select-option"
          />
        </ValidationObserver>

        <!-- Expiration step -->
        <ValidationObserver
          v-if="step === 1"
          ref="expirationObserver"
          tag="div"
          class="tw-flex-1"
          data-test="expiration-step"
        >
          <h5
            v-text="$options.filters.capitalize($t('proposal_form.proposal_expiration'))"
            class="tw-font-normal tw-mb-4"
            data-test="title"
          />
          <p
            class="tw-text-gray-700 tw-mb-5"
            v-text="$t('proposal_form.proposal_expiration_explication')"
            data-test="explanation"
          />

          <div class="tw-flex tw-mb-4">
            <ValidationProvider
              ref="expiration_date-provider"
              rules="required"
              :name="$t('app.labels.date')"
              slim
            >
              <template slot-scope="{ invalid, errors, clear, validated }">
                <ctk-date-time-picker
                  id="expiration-date"
                  v-model="formData.expiration"
                  :label="$t('proposal_form.expiration_date') | capitalize"
                  :locale="$i18n.locale"
                  :minute-interval="10"
                  :min-date="allowedDates.pickup"
                  :hint="errors[0]"
                  :error="invalid && validated || errors && errors.length > 0"
                  class="expiry-date"
                  color="#287696"
                  format="YYYY-MM-DDTHH:mm"
                  no-button-now
                  data-test="expiration-date"
                  @input="clear"
                />
              </template>
            </ValidationProvider>
          </div>
          <ValidationProvider
            ref="comment-provider"
            :name="$t('comment')"
            slim
          >
            <template slot-scope="{ invalid, errors, validated }">
              <ctk-input-textarea
                id="commentInput"
                v-model="formData.expirationComment"
                :label="$t('app.labels.comment')"
                :hint="errors[0]"
                :error="invalid && validated || errors && errors.length > 0"
                data-test="expiration-comment"
              />
            </template>
          </ValidationProvider>
        </ValidationObserver>

        <!-- Footer -->
        <div
          class="dialog-proposal__footer tw-w-full tw-pt-3 tw-mt-4 tw-flex-shrink-0"
          data-test="footer"
        >
          <div
            class="tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-justify-between"
          >
            <div class="tw-w-full md:tw-w-auto">
              <ui-button
                class="tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0"
                variant="link"
                data-test="back-button"
                @click="previous"
              >
                {{ $t(step === 0 ? 'cancel' : 'back') | capitalize }}
              </ui-button>
            </div>

            <ui-button
              :disabled="isNextDisabled || $wait.is('creating proposal')"
              :loading="$wait.is('creating proposal')"
              class="tw-w-full md:tw-w-auto"
              variant="primary"
              data-test="validate-button"
              @click="step === 0 ? savePriceDates() : saveExpiration()"
            >
              {{ hasOptions ? $t('app.buttons.next') : $t('proposal_form.submit_button') }}
            </ui-button>
          </div>
        </div>
      </div>
    </div>
  </ctk-aside-dialog>
</template>

<script>
  import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch } from '@vue/composition-api'
  import currency from 'currency.js'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

  import { TabsBar } from '@/components/CtkTabsLayout'
  import CtkAsideDialog from '@/components/CtkAsideDialog/index.vue'
  import CtkAddDriverForm from '@/components/CtkAddDriverForm/index.vue'
  import CtkChooseDriverForm from '@/components/CtkChooseDriverForm/index.vue'
  import SummaryOffer from '@/views/Carriers/Offers/components/DialogBooking/_subs/SummaryOffer/index.vue'
  import OfferSummaryCard from '@/views/Carriers/Offers/components/DialogBooking/_subs/OfferSummaryCard/index.vue'
  import NewShipmentAcceptationDialogReassurance from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/_subs/NewShipmentAcceptationDialogReassurance/index.vue'

  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import UiFatRadioItem from '@/components/UI/FatRadioGroup/FatRadioItem/index.vue'
  import CtkOfferCard from '@/components/CtkOfferCard/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import CtkDialog from '@/components/CtkDialog/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useStore from '@/composables/useStore'
  import useMatomo from '@/composables/useMatomo'
  import useMoment from '@/composables/useMoment'
  import useWait from '@/composables/useWait'
  import useRoute from '@/composables/useRoute'

  import { OfferProposal, Proposal } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import Hotjar from '@/plugins/VueHotjar'
  import { EventBus } from '@/services/EventBus'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import useI18n from '@/composables/useI18n'

  /**
   * @module component - DialogProposal
   * @param {boolean} renew - Says if the dialog is in renew state. Note that if renew = false
   * is an edit, renew being undefined is a new proposal and renew = true is a renew.
   */
  export default defineComponent({
    name: 'DialogProposal',
    components: {
      TabsBar,
      CtkAsideDialog,
      CtkAddDriverForm,
      CtkChooseDriverForm,
      SummaryOffer,
      OfferSummaryCard,
      NewShipmentAcceptationDialogReassurance,
      CtkDateTimePicker,
      UiFatRadioGroup,
      UiFatRadioItem,

      CtkOfferCard,
      CtkInputText,
      CtkInputTextarea,
      CtkDialog
    },
    emits: [
      'value',
      'proposal-created'
    ],
    model: {
      prop: 'value'
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      renew: {
        type: Boolean,
        default: null
      }
    },
    setup (props, { refs, emit }) {
      const { state: modalState } = useModelGetterSetter(props, 'value')
      const store = useStore()
      const matomo = useMatomo()
      const moment = useMoment()
      const wait = useWait()
      const route = useRoute()
      const i18n = useI18n()

      const { renew } = toRefs(props)

      const step = ref(1)
      /** @type {import('@vue/composition-api').Ref<boolean|null>} */
      const hasPriceChange = ref(null)
      /** @type {import('@vue/composition-api').Ref<boolean|null>} */
      const hasDatesChange = ref(null)

      /**
       * @type {{
       *   price: number | null
       *   dates: {
       *     pickup: string | null
       *     delivery: string | null
       *   }
       *   expiration: string | null
       *   expirationComment: string | null
       * }}
       */
      const formData = reactive({
        price: null,
        dates: {
          pickup: null,
          delivery: null
        },
        expiration: null,
        expirationComment: null
      })

      const error = reactive({
        expirationDate: {
          bool: false,
          hint: null
        }
      })

      const allowedDates = reactive({
        pickup: moment.value().format('YYYY-MM-DD'),
        delivery: moment.value().format('YYYY-MM-DD')
      })

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])
      const getCurrentProposal = computed(() => store.value.getters['offers/getCurrentProposal'])

      /**
       * @type {import('@vue/composition-api').Ref<Array<string>>}
       **/
      const summaryOptionsNeeded = ref([])

      const hasOptions = computed(() => summaryOptionsNeeded.value.length > 0)

      const isNextDisabled = computed(() => {
        if (hasPriceChange.value === null || hasDatesChange.value === null) {
          return true
        }

        if (hasPriceChange.value === false && hasDatesChange.value === false) {
          return true
        }

        return false
      })

      const proposal = computed(() => {
        return (getCurrentOffer.value && getCurrentOffer.value.proposal) ||
          getCurrentProposal.value
      })

      /**
       * Returns the current offer price (or carrier price).
       * Note that sometimes we may have "price" or "carrier_price" according
       * to where the component is used. This is a backend issue.
       * @type {import('@vue/composition-api').Ref<number|null>} price
       */
      const price = computed(() => {
        const offer = getCurrentOffer.value

        return offer && offer.pricing
          ? offer.pricing.price || offer.pricing.carrier_price
          : null
      })

      /**
       * Returns true if the price is greater than the offer price
       * @function hasProposalWarning
       * @returns {boolean}
       */
      const hasProposalWarning = computed(() => {
        if (formData.price === null) return
        if (!getCurrentOffer.value || !getCurrentOffer.value.pricing) return

        const { price, carrier_price: carrierPrice } = getCurrentOffer.value.pricing
        const priceCents = currency(formData.price).intValue

        return priceCents > (price || carrierPrice)
      })

      /**
       * Returns the appropriate warning message according to the percentage
       * of the difference between the price and the offer price.
       * @function proposalWarning
       * @returns {Object|void}
       */
      const proposalWarning = computed(() => {
        if (formData.price === null) return

        const { price, carrier_price: carrierPrice } = getCurrentOffer.value.pricing
        const priceCents = currency(formData.price).intValue
        const percentage = Math.abs(100 - ((priceCents * 100) / (price || carrierPrice)))

        if (matomo) {
          matomo.trackEvent('Offers', 'Displayed Proposal Relevance', getCurrentOffer.value.uuid, percentage.toFixed(2))
        }

        if (percentage <= 20) {
          return {
            message: 'offers.paragraphs.proposal.price-warning.lowest',
            key: 'lowest'
          }
        } else if (percentage > 20 && percentage <= 50) {
          return {
            message: 'offers.paragraphs.proposal.price-warning.low',
            key: 'low'
          }
        } else if (percentage > 50 && percentage <= 100) {
          return {
            message: 'offers.paragraphs.proposal.price-warning.high',
            key: 'high'
          }
        } else {
          return {
            message: 'offers.paragraphs.proposal.price-warning.highest',
            key: 'highest'
          }
        }
      })

      /**
       * @function getOptionsNeeded
       * @returns {Array<string>}
       */
      function getOptionsNeeded () {
        if (!getCurrentOffer.value) return []

        /**
         * @function showSummaryDirection
         * @param {{ [key: string]: any}} offer
         * @param {string} direction
         */
        const showSummaryDirection = (offer, direction) => {
          const { address, handling } = offer[direction]
          const { driver, tail_lift: tailLift } = handling

          const vehicleTypeIssue = ['lightweight_vehicle', 'carrier_truck']
          const hasAccessibilityIssue = address && !!address.accessibility &&
            !!address.accessibility.vehicle_type &&
            vehicleTypeIssue.includes(address.accessibility.vehicle_type.key)

          return (driver || tailLift || !!offer[direction].comment || hasAccessibilityIssue)
        }

        /**
         * @function showSummaryGoods
         * @param {{ [key: string]: any }} offer
         * @returns {boolean}
         */
        const showSummaryGoods = (offer) => {
          return !!offer.load.description
        }

        return [
          ...(showSummaryDirection(getCurrentOffer.value, 'pickup') ? ['pickup'] : []),
          ...(showSummaryDirection(getCurrentOffer.value, 'delivery') ? ['delivery'] : []),
          ...(showSummaryGoods(getCurrentOffer.value) ? ['goods'] : [])
        ]
      }

      function initializeDialog () {
        summaryOptionsNeeded.value = getOptionsNeeded()
        step.value = 0

        /**
         * Reset the formData values to their initial state.
         * Note that there is an issue with reactive that cannot be overrided,
         * that's why values are overrided manually.
         *
         * Assign the default values to the formData according to
         * the proposal from the offer or the current proposal otherwise.
         */
        formData.price = proposal.value && proposal.value.price
          ? proposal.value.price / 100
          : null
        formData.dates.pickup = proposal.value && proposal.value.pickup_date
          ? proposal.value.pickup_date
          : null
        formData.dates.delivery = proposal.value && proposal.value.delivery_date
          ? proposal.value.delivery_date
          : null
        formData.expiration = proposal.value && proposal.value.expires_at
          ? proposal.value.expires_at
          : null
        formData.expirationComment = proposal.value && proposal.value.comment
          ? proposal.value.comment
          : null

        /**
         * Determine if either the price or the dates were initially changed
         * in the case of an edit. Otherwise fallback to no choice.
         */
        hasPriceChange.value = formData.price === null
          ? formData.dates.pickup === null
            ? null
            : false
          : true
        hasDatesChange.value = formData.dates.pickup === null
          ? formData.price === null
            ? null
            : false
          : true
      }

      function previous () {
        if (step.value === 0) {
          modalState.value = false
          return
        }

        step.value--
      }

      function buildProposalBody () {
        const { price, dates, expiration, expirationComment } = formData

        /**
         * @type {{
         *   price?: number
         *   comment?: string
         *   pickup_date?: string
         *   delivery_date?: string
         *   expiration_date: string
         * }}
         */
        const body = {
          expiration_date: moment.value(expiration).format()
        }

        if (hasPriceChange.value && price !== null) {
          body.price = currency(price).intValue
        }

        if (hasDatesChange.value && dates.pickup && dates.delivery) {
          body.pickup_date = moment.value(dates.pickup).format('YYYY-MM-DD')
          body.delivery_date = moment.value(dates.delivery).format('YYYY-MM-DD')
        }

        if (expirationComment !== null && expirationComment !== '') {
          body.comment = expirationComment
        }

        return body
      }

      /**
       * @function proposalConfirmation
       * @param {any} body
       * @param {string} offerUuid
       */
      function proposalConfirmation (body, offerUuid) {
        /**
         * Close the dialog and triggers the display of the confirmation dialog
         */
        modalState.value = false
        emit('proposal-created')

        body.status = 'pending'
        body.expires_at = body.expiration_date

        if (['Proposals', 'ProposalStates'].includes(route.value.name)) {
          EventBus.$emit('refresh-proposal-section')

          if (!body.modification) {
            setTimeout(() => {
              store.value.dispatch('offers/deleteProposalInProposalsWithId', proposal.value.uuid)
              store.value.dispatch('offers/resetCurrentOffer')
            }, 1000)
          }

          /**
           * TODO: Rly? Can't just use the two already existing actions to update this instead
           * of having a different one?
           */
          store.value.dispatch('offers/updateProposalInProposalsAndInCurrentOffer', { proposal: body })
        } else {
          store.value.dispatch('offers/addKeyValueToAnOffer', {
            offerUuid,
            key: 'proposal',
            value: body
          })

          store.value.dispatch('offers/updateProposalToCurrentOffer', {
            offer: offerUuid,
            proposal: body
          })
        }

        Hotjar.tag('Active Carrier')

        /**
         * Fetch the offer to refetch the offer informations; mainly for the
         * proposal_count and the best proposal price.
         */
        store.value.dispatch('offers/retrieveProposalsMetrics')
          .catch(() => { /* noop */ })

        store.value.dispatch('offers/retrieveOffer', offerUuid)
          // @ts-ignore
          .then((response) => {
            /**
             * Update the offer in the list
             */
            const {
              proposal_count: proposalCount,
              lowest_proposal_price: lowestProposalPrice
            } = response.data

            store.value.dispatch('offers/addKeyValueToAnOffer', {
              offerUuid,
              key: 'proposal_count',
              value: proposalCount
            })

            if (typeof lowestProposalPrice !== 'undefined') {
              store.value.dispatch('offers/addKeyValueToAnOffer', {
                offerUuid,
                key: 'lowest_proposal_price',
                value: lowestProposalPrice
              })
            }
          })
          .catch(() => { /* noop */ })
      }

      async function savePriceDates () {
        const offerUuid = getCurrentOffer.value.uuid

        /**
         * TODO: A timeout was set here to bypass the debounce added by our
         * own integration of Matomo trackEvent. Find a way to send multiple events
         * at the same time, without the need of a timeout.
         */
        if (matomo) {
          matomo.trackEvent('Offers', renew.value
            ? 'Confirmed Proposal Reactivation'
            : 'Confirmed Proposal', offerUuid)
        }

        if (!refs.priceDatesObserver) return
        // @ts-ignore
        const valid = await refs.priceDatesObserver.validate()
        if (!valid) return

        /**
         * Manually validate the dates as we did not implement any validation through
         * the datepicker. A good improvement would be to be able to validate the dates
         * using VeeValidate around the datepicker.
         */
        if (hasDatesChange.value) {
          const isPickupValid = !!formData.dates.pickup && moment.value(formData.dates.pickup, 'YYYY-MM-DD', true).isValid()
          const isDeliveryValid = !!formData.dates.delivery && moment.value(formData.dates.delivery, 'YYYY-MM-DD', true).isValid()
          const isDatesValid = isPickupValid && isDeliveryValid

          if (!isDatesValid) {
            if (!isPickupValid) {
              // @ts-ignore
              refs['pickup_date-provider'].setErrors([
                i18n.value.t('proposal_dialog.error_message.invalid_date').toString()
              ])
            }

            if (!isDeliveryValid) {
              // @ts-ignore
              refs['delivery_date-provider'].setErrors([
                i18n.value.t('proposal_dialog.error_message.invalid_date').toString()
              ])
            }

            return
          }
        }

        step.value = 1
      }

      async function saveExpiration () {
        if (!refs.expirationObserver) return
        // @ts-ignore
        const valid = await refs.expirationObserver.validate()
        if (!valid) return

        /**
         * Manually validate the expiration date
         */
        const isExpirationValid = !!formData.expiration && moment.value(formData.expiration).isValid()

        if (!isExpirationValid) {
          // @ts-ignore
          refs['expiration_date-provider'].setErrors([
            i18n.value.t('proposal_dialog.error_message.invalid_date').toString()
          ])

          return
        }

        if (hasOptions.value) {
          step.value = 2
        } else {
          await saveProposal()
        }
      }

      async function saveProposal () {
        const offerUuid = getCurrentOffer.value.uuid

        if (wait.is('creating proposal')) return
        wait.start('creating proposal')

        /**
         * Before updating or creating the proposal, we want to know if we should update it
         * or create it because the state of the proposal may have changed.
         * For that, we want to refresh the offer to get the latest state of the proposal.
         */
        let isProposalUpdate = false
        if (proposal.value && proposal.value.uuid) {
          try {
            const response = await Proposal.get({
              cid: store.value.getters['auth/getCid'],
              pid: proposal.value.uuid
            })

            isProposalUpdate = response.data.status === 'pending'
          } catch (err) {
            /**
             * TODO: Handle proposal fetch error
             */
            console.error('err', err)
          }
        }

        const body = buildProposalBody()
        const params = {
          cid: store.value.getters['auth/getCid'],
          ...(!isProposalUpdate && { oid: offerUuid }),
          ...(isProposalUpdate && { pid: proposal.value.uuid })
        }

        const proposalRequest = isProposalUpdate
          ? Proposal.update(params, body)
          : OfferProposal.save(params, body)

        proposalRequest
          .then(({ data }) => {
            proposalConfirmation({
              ...body,
              uuid: data.uuid ? data.uuid : proposal.value.uuid,
              modification: !data.uuid
            }, offerUuid)
          })
          .catch(async (err) => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              if (err.response.status === 404) {
                modalState.value = false

                showToaster(null, i18n.value.t('offers.paragraphs.not_found'), {
                  type: 'error',
                  position: 'bottom-right'
                })

                return
              }

              let errorMessage = null
              if (data.error.violations) {
                const priceDatesViolations = ['pickup_date', 'delivery_date', 'price']
                const expirationViolations = ['comment', 'expiration_date']

                const hasExpirationViolation = data.error.violations
                  // @ts-ignore
                  .some(violation => expirationViolations.includes(violation.property_path))
                const hasDatesViolations = data.error.violations
                  // @ts-ignore
                  .some(violation => priceDatesViolations.includes(violation.property_path))

                if (hasExpirationViolation) {
                  step.value = 1
                } else if (hasDatesViolations) {
                  step.value = 0
                }

                await nextTick()

                handlePropertyPathViolations(data.error.violations, refs)
              } else {
                errorMessage = data.error.detail || data.error.title
              }

              if (errorMessage) {
                showToaster(null, errorMessage, {
                  type: 'error',
                  position: 'bottom-right'
                })
              }
            }
          })
          .finally(() => {
            wait.end('creating proposal')
          })
      }

      onMounted(() => {
        initializeDialog()
      })

      watch(modalState, v => {
        if (v) {
          initializeDialog()
        }
      })

      return {
        price,
        step,
        getCurrentOffer,
        summaryOptionsNeeded,
        modalState,
        getOptionsNeeded,
        previous,
        initializeDialog,
        isNextDisabled,
        hasOptions,

        hasPriceChange,
        hasDatesChange,
        allowedDates,
        formData,
        error,
        savePriceDates,
        saveProposal,
        saveExpiration,

        hasProposalWarning,
        proposalWarning
      }
    }
  })
</script>

<style lang="scss">
.dialog-proposal .modal-container {
  height: clamp(300px, 743px, 100%);
}
.dialog-proposal__radio .ui-fat-radio-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  min-height: 50px;
}
.dialog-proposal__radio .ui-fat-radio-item:not(:last-child) {
  margin-right: 1rem;
}
.dialog-proposal__radio .ui-fat-radio-item:first-child.active::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -5px;
  content: '';
  width: 10px;
  height: 10px;
  background-color: $info;
  transform: rotate(45deg);
}
.dialog-proposal__radio .ui-fat-radio-item:first-child:hover.active:not(:disabled)::after, .dialog-proposal__radio .ui-fat-radio-item:first-child:focus-visible.active:not(:disabled)::after {
  background-color: lighten($info, 5%);
}
.dialog-proposal .proposal-warning {
  --tw-text-opacity: 1;
  color: rgba(215, 40, 63, var(--tw-text-opacity));
}
.dialog-proposal .proposal-warning::before {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
  border-radius: 9999px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px;
  margin-right: 0px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  content: '';
  left: 3px;
  width: 5px;
  height: 5px;
}
.dialog-proposal .proposal-warning--lowest {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.dialog-proposal .proposal-warning--lowest::before {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
}
.dialog-proposal .proposal-warning--low {
  --tw-text-opacity: 1;
  color: rgba(245, 166, 35, var(--tw-text-opacity));
}
.dialog-proposal .proposal-warning--low::before {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 166, 35, var(--tw-bg-opacity));
}
.dialog-proposal .ctk-aside-dialog__main, .dialog-proposal .ctk-aside-dialog__body, .dialog-proposal .modal-body {
  height: 100%;
}
.dialog-proposal .summary-offer .ctk-address-infos__title__content, .dialog-proposal .summary-offer .ctk-goods-infos__title__content {
  font-weight: 500;
  font-size: 0.875rem;
}
.dialog-proposal .summary-offer .ctk-address-infos__title__icon, .dialog-proposal .summary-offer .ctk-goods-infos__title__icon {
  font-size: 1.875rem;
}
.dialog-proposal--step-1 .ctk-aside-dialog__aside__container, .dialog-proposal--step-2 .ctk-aside-dialog__aside__container {
  display: none;
}
@media (min-width: 770px) {
  .dialog-proposal--step-1 .ctk-aside-dialog__aside__container, .dialog-proposal--step-2 .ctk-aside-dialog__aside__container {
    display: block;
  }
  .dialog-proposal .summary-offer__footer, .dialog-proposal__footer {
    position: relative;
  }
  .dialog-proposal .summary-offer__footer::before, .dialog-proposal__footer::before {
    position: absolute;
    content: '';
    left: -2.5rem;
    top: 0;
    width: calc(100% + 5rem);
    height: 10px;
    box-shadow: 0 -2px 2px 0 rgba($secondary-text, 0.25);
  }
}
</style>

export default () => ({
  currentMission: {},
  missions: {
    items: [],
    meta: {
      item_count: null,
      pagination: {
        current_page: null,
        page_count: null,
        page_size: null
      }
    },
    filters: {
      tracking_to_pickup: false,
      tracking_to_deliver: false,
      tracking_to_upload_pod: false
    }
  },
  metrics: {
    me: {
      planned: null,
      in_progress: null,
      completed: null,
      cancelled: null,
      total: null
    },
    company: {
      planned: null,
      in_progress: null,
      completed: null,
      cancelled: null,
      total: null
    }
  },
  billing: null,
  currentMissionStatus: 'planned',
  missionUuidToShow: null,
  itineraries: [],
  fetchMissionsActive: false
})

// @ts-nocheck
import Vue from 'vue'
import { i18n } from '@/locales'

/**
 * @function showToaster
 * @param {any} root
 * @param {string|import('vue-i18n').TranslateResult} text
 * @param {import('vue-toasted').ToastOptions} params
 */
export function showToaster (root, text, params = {}) {
  if (!text) {
    throw new Error('No text provided for the toaster.')
  }

  Vue.toasted.show(text.toString(), {
    icon: params.icon || false,
    duration: params.duration || 10000,
    position: params.position || 'bottom-left',
    type: params.type || false,
    action: params.action || {
      text: i18n.t('close'),
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  })
}

import store from '@/store'

const createExternalRequestShipment = async (to, from, next) => {
  try {
    const { uuid: externalRequestUuid } = to.params
    const { external_id: externalId } = to.query

    if (externalRequestUuid && externalId) {
      store.dispatch('externalRequests/setExternalRequest', { uuid: externalRequestUuid, external_id: externalId })

      return next({ name: 'ExternalRequests' })
    } else {
      return next({ name: 'SignIn' })
    }
  } catch (error) {
    if (error) {
      console.log(error)
    }
  }
}

export default createExternalRequestShipment

// @ts-nocheck
export default {
  /**
   * Returns true if the impersonate navbar should be visible to the user
   * @function isImpersonateVisible
   * @returns {boolean}
   */
  isImpersonateVisible (state, getters, rootState, rootGetters) {
    const userInfos = rootGetters['auth/getUserInfos']
    const isImpersonating = !!rootGetters['auth/getImpersonate']

    return !state.impersonateClosed && !!userInfos && isImpersonating
  },
  /**
   * Returns true if the welcome dialog was closed manually by the end user.
   * @function isWelcomeDialogClosed
   * @returns {boolean}
   */
  isWelcomeDialogClosed: state => state.welcomeDialogClosed,
  getAlerts: state => state.alerts,
  getShowNavBar: state => state.showNavBar,
  isWelcomeVisible: state => state.welcomeVisible,
  isSidebarOpen: state => state.sidebarOpen,
  getShowAuthCtaBar: state => state.showAuthCtaBar
}

// @ts-nocheck
import { i18n } from '@/locales'

export default {
  getUserInfos: ({ userInfos }) => userInfos,
  getUserFullName: (state, { getUserInfos }) => {
    if (!getUserInfos) return null

    const {
      first_name: firstName,
      last_name: lastName
    } = getUserInfos

    return `${firstName.trim()} ${lastName.trim()}`
  },
  getCompany: (state, { getUserInfos }) => getUserInfos && getUserInfos.companies && getUserInfos.companies[0],
  getCompanyInfos: state => state.company,
  getCompanyAnimation: (state, { getCompanyInfos }) => getCompanyInfos && getCompanyInfos.animation_user,
  isCompanyPayingCC: (state, { getCompanyInfos }) => getCompanyInfos && getCompanyInfos.billing && getCompanyInfos.billing.payment_mode === 'immediate',
  isBillingGrouped: (state, { getCompanyInfos }) => getCompanyInfos && getCompanyInfos.billing && getCompanyInfos.billing.billing_mode === 'grouped',
  getCid: (state, { getCompany }) => getCompany && getCompany.uuid,
  getToken: ({ token }) => token,
  getImpersonate: ({ impersonate }) => impersonate,
  getPaymentSources: ({ paymentSources }) => paymentSources,
  isImpersonating: ({ isImpersonating }) => isImpersonating,
  isAccountLocked: (state, { getCompany }) => getCompany && getCompany.status === 'locked',
  isOnboarded: (state, { getCompany }) => getCompany && getCompany.is_onboarded,
  isPreRegistered: (state, { getCompany }) => getCompany && getCompany.status === 'onboarding',
  isCompanyOwner: (state, { getCompany }) => getCompany && getCompany.is_owner,
  getVatNumber: ({ company }) => company && company.billing && company.billing.vat_number,
  isFrenchShipper (state, g) {
    const hasFrenchVATNumber = g.getVatNumber && g.getVatNumber.slice(0, 2) === 'FR'
    return hasFrenchVATNumber || i18n.locale === 'fr'
  },
  getFeatureFlags: (state, { getCompany }) => getCompany.settings,
  isFirstConnection: (state, { getUserInfos }, rootState, rootGetters) => getUserInfos && getUserInfos.is_first_connection && !rootGetters['ui/isWelcomeDialogClosed'],
  getRedirectUrl: state => state.redirectUrl
}

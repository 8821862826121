export const defaultDirection = {
  address: null,
  comment: null,
  meeting: null,
  contact: {
    name: null,
    phone: {
      number: null,
      country: null
    }
  },
  timeslot: {
    date: null,
    flexible: true,
    start_time: 540,
    end_time: 1020
  },
  handling: {
    driver: null,
    tail_lift: null
  }
}

export const defaultLoad = {
  type: 'pallets',
  quantity: null,
  height: null,
  weight: null,
  length: null,
  width: null,
  format: null,
  pallets: [],
  insuranceType: null,
  insuredLoadValue: null
}

export default () => ({
  quotation: null,
  selectedPrice: null,
  customPrice: null,
  isCustomPriceSelected: false,
  currentPrice: null,
  selectedDateType: 'tailored',
  quotationValidated: false,
  pickup: JSON.parse(JSON.stringify(defaultDirection)),
  delivery: JSON.parse(JSON.stringify(defaultDirection)),
  load: JSON.parse(JSON.stringify(defaultLoad)),
  addressesDistance: null,
  isConfirmation: false,
  vatNumber: null,
  phoneNumber: null,
  shipperReference: null,
  expirationDate: null,
  fetchedAddresses: {
    pickup: [],
    delivery: []
  },
  fetchedShipments: [],
  pallets: [
    {
      quantity: null,
      format: null,
      length: null,
      width: null
    }
  ],
  guards: {
    pickup_address: false,
    delivery_address: false,
    dates: false,
    handling: false,
    goods: false,
    quotation: false
  }
})

import { extend } from 'vee-validate'

import TelephoneValidator from './telephone'
import SizeValidator from './size'
import MimesValidator from './mimes'
import CountryValidator from './country'

export default {
  init () {
    extend(TelephoneValidator.name, TelephoneValidator.validator)
    extend(SizeValidator.name, SizeValidator.validator)
    extend(MimesValidator.name, MimesValidator.validator)
    extend(CountryValidator.name, CountryValidator.validator)
  }
}

<template>
  <div class="ctk-phone-number tw-flex">
    <country-selector
      ref="CountrySelector"
      :id="`${id}_country_selector`"
      v-model="countryCode"
      :items="codesCountries"
      :error="shouldChooseCountry"
      :hint="shouldChooseCountry ? $t('choose_country') : null"
      :disabled="disabled"
      :preferred-countries="preferredCountries"
      :only-countries="onlyCountries"
      :ignored-countries="ignoredCountries"
      :full-width="false"
      :label="$t('app.labels.country_code')"
      :required="required"
      class="input-country-selector"
      data-test="country-select"
      @input="$emit('update-country', countryCode)"
    />
    <ctk-input-text
      ref="PhoneNumberInput"
      :id="`${id}_phone_number`"
      v-bind="$attrs"
      v-model="phoneNumber"
      :label="inputLabel"
      :color="color"
      :hint="hint"
      :error="error"
      :valid-color="validColor"
      :disabled="disabled"
      :required="required"
      type="tel"
      class="ctk-phone-number__input tw-flex-1"
      @focus="$emit('phone-number-focused')"
    />
  </div>
</template>

<script>
  import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'
  import usePhoneCodeCountries from '@/composables/usePhoneCodeCountries'

  import CtkInputText from '@/components/CtkInputs/CtkInputText'
  import CountrySelector from './_subs/CountrySelector'

  export default {
    name: 'CtkPhoneNumberInput',
    inheritAttrs: false,
    components: {
      CtkInputText,
      CountrySelector
    },
    props: {
      value: { type: String, default: null },
      country: {
        type: String,
        default: 'FR'
      },
      id: { type: String, default: 'CtkPhoneNumberInput' },
      color: { type: String, default: '#287696' },
      validColor: { type: String, default: '#96bf31' },
      label: { type: String, default: null },
      preferredCountries: { type: Boolean, default: true },
      onlyCountries: { type: Array, default: null },
      ignoredCountries: { type: Array, default: () => ([]) },
      error: { type: Boolean, default: false },
      hint: { type: [String, Boolean], default: null },
      disabled: { type: Boolean, default: false },
      required: { type: Boolean, default: false }
    },
    data () {
      return {
        results: {}
      }
    },
    watch: {
      country (country) {
        /**
         * If the country code changes, force the input to be updated with
         * the right format.
         * This is triggered twice.
         */
        const asYouType = this.getAsYouTypeFormat({
          countryCode: country,
          phoneNumber: this.value
        })

        this.$emit('input', asYouType)
      }
    },
    computed: {
      codesCountries () {
        const { countries } = usePhoneCodeCountries()
        return countries
      },
      countryCode: {
        get () {
          return this.country
        },
        set (newCountry) {
          this.$emit('update:country', newCountry)
          this.emitValues({ countryCode: newCountry, phoneNumber: this.phoneNumber })
        }
      },
      phoneNumber: {
        get () {
          return this.value
        },
        set (newPhone) {
          /**
           * Force the update of the country whenever the user fills the phone number
           * field.
           */
          this.$emit('update:country', this.countryCode)
          this.emitValues({ countryCode: this.countryCode, phoneNumber: newPhone })
        }
      },
      shouldChooseCountry () {
        return !this.countryCode && !!this.phoneNumber
      },
      isValid () {
        return this.results.isValid
      },
      inputLabel () {
        return this.label || this.$t('phone_number')
      }
    },
    methods: {
      getAsYouTypeFormat (payload) {
        const asYouType = new AsYouType(payload.countryCode)
        return asYouType.input(payload.phoneNumber)
      },
      getParsePhoneNumberFromString ({ phoneNumber, countryCode }) {
        const parsing = phoneNumber && countryCode ? parsePhoneNumberFromString(phoneNumber, countryCode) : null
        return {
          phoneNumber: phoneNumber || null,
          countryCode: countryCode,
          isValid: false,
          ...(parsing
            ? {
              formattedNumber: parsing.number,
              nationalNumber: parsing.nationalNumber,
              isValid: parsing.isValid(),
              type: parsing.getType(),
              formatInternational: parsing.formatInternational(),
              formatNational: parsing.formatNational(),
              uri: parsing.getURI()
            }
            : null
          )
        }
      },
      emitValues (payload) {
        const asYouType = this.getAsYouTypeFormat(payload)
        this.$emit('input', asYouType)
        this.results = this.getParsePhoneNumberFromString(payload)
        this.$emit('update', this.results)
      }
    }
  }
</script>

<style lang="scss">

  .ctk-phone-number {
    .ctk-input-text__field {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .country-selector {
      flex: 0 0 115px;
      width: 115px;
      min-width: 115px;
      max-width: 115px;
      margin-right: -1px;

      .ctk-input-select__wrapper {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .ctk-input-select-list {
        max-width: 200px;
      }
    }
  }

</style>

// @ts-ignore
import { find, get, has, isEmpty, reduce } from 'underscore'
// @ts-ignore
import { allCountries } from '@/../config/chronoinfos'

// @ts-ignore
const availableCountries = allCountries.filter(country => !!country.opened)

const useCountryLists = () => {
  // @ts-ignore
  const reduceCountryListByAttr = (attrPath, targetAttrPath = 'iso-2') => reduce(allCountries, (filteredCountries, country) => {
    if (has(country, attrPath)) {
      // @ts-ignore
      filteredCountries.push(get(country, targetAttrPath))
    }
    return filteredCountries
  }, [])

  // * Unauthorized countries cannot be selected for internal travels (ex: PL -> PL)
  const getUnauthorizedSameCountries = () => reduceCountryListByAttr('disabled-for-home')

  const getBetaCountriesList = () => reduceCountryListByAttr('beta')

  const getNewCountriesList = () => reduceCountryListByAttr('new')

  const getEuroDeliveryCountriesList = () => reduceCountryListByAttr(['delivery', 'euro'])

  const getEmptyCountriesList = () => Object.fromEntries(
    // @ts-ignore
    allCountries.map(country => {
      return [country['iso-2'], []]
    })
  )

  const getCountry = ({ attrPath, val } = { attrPath: ['iso-2'], val: null }) => {
    if (isEmpty(attrPath) || !val) return null

    return find(allCountries, c => get(c, attrPath) === val) || null
  }

  // @ts-ignore
  const getCountryByIso2 = (iso2) => getCountry({ attrPath: ['iso-2'], val: iso2 })

  // @ts-ignore
  const isCountryOpened = (iso2) => availableCountries.some(country => country['iso-2'] === iso2)

  // @ts-ignore
  const hasNewCountry = () => !!getCountry({ attrPath: ['new'], val: true })

  const getAvailableCountries = () => availableCountries

  const getAllCountries = () => allCountries

  // * Returns the list of the countries eligible to the Ad Valorem insurance
  // @ts-ignore
  const getAdValoremEligibleCountries = () => allCountries.filter(country => !!country.ad_valorem_insurance)

  return {
    getAdValoremEligibleCountries,
    getAllCountries,
    getAvailableCountries,
    getBetaCountriesList,
    getCountry,
    getCountryByIso2,
    getEmptyCountriesList,
    getEuroDeliveryCountriesList,
    getNewCountriesList,
    getUnauthorizedSameCountries,
    hasNewCountry,
    isCountryOpened
  }
}

export default useCountryLists

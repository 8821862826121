import { computed, ref } from '@vue/composition-api'

export default function useColorUuid () {
  /**
   * The number of variations to generate from the base colors.
   * The more variations there is, the less color collision occurs.
   * @const VARIATION_COUNT
   */
  const VARIATION_COUNT = 128

  const baseColors = ref([
    '#f9f9f9',
    '#E9E9E9',
    '#676A6C',
    '#21222E',
    '#303144',
    '#6ACFB5',
    '#2EBCE8',
    '#2595B8',
    '#F5F9EA',
    '#add251',
    '#769627',
    '#d6ebf0',
    '#3396be',
    '#287696',
    '#1d566e',
    '#8443b9',
    '#F5E5CB',
    '#eadac0',
    '#F5A623',
    '#896F2D',
    '#EFCCD1',
    '#df5365',
    '#D7283F',
    '#ac2032',
    '#763336',
    '#FFA300',
    '#E49E95'
  ])

  /**
   * Convert RGB values to HSL.
   * Source: http://stackoverflow.com/a/9493060/82826
   * @function rgbToHsl
   * @param {number} _r
   * @param {number} _g
   * @param {number} _b
   * @returns {{ h: number, s: number, l: number }} hsl color object
   */
  function rgbToHsl (_r, _g, _b) {
    const r = _r / 255
    const g = _g / 255
    const b = _b / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)

    let h
    let s
    const l = (max + min) / 2

    if (max === min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h /= 6
    }

    return {
      h: h * 360,
      s: s * 100,
      l: l * 100
    }
  }

  /**
   * Convert Hex to RGB.
   * Source: http://stackoverflow.com/a/5624139/82826
   * @function hexToRgb
   * @param {string} hex
   * @returns {{ r: number, g: number, b: number }|null} rgb color object
   */
  function hexToRgb (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : null
  }

  /**
   * Returns if the background should be either black or white, based on the
   * color of the text.
   * @function getLumaFromRgb
   * @param {{ r: number, g: number, b: number }} rgb
   * @returns {string}
   */
  function getLumaFromRgb (rgb) {
    const luma = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b
    return luma > 186 ? 'black' : 'white'
  }

  /**
   * Returns the nearest color from the generated colors
   * that matches our HSL color.
   * @function getNearestColor
   * @param {{ h: number, s: number, l: number }} hsl
   * @returns {{ h: number, s: number, l: number }}
   */
  function findNearestColor (hsl) {
    const sortedColors = colorsWithVariations.value
      .map((color) => {
        const distance = Math.sqrt(
          Math.pow(hsl.h - color.h, 2) +
            Math.pow(hsl.s - color.s, 2) +
            Math.pow(hsl.l - color.l, 2)
        )

        return {
          color,
          distance
        }
      })
      .sort((a, b) => a.distance - b.distance)
      .map((color) => color.color)

    return sortedColors[0]
  }

  const colorsWithVariations = computed(() => {
    const colors = baseColors.value.map((color) => {
      const rgb = hexToRgb(color)
      const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)
      const variations = Array.from(new Array(VARIATION_COUNT))
        .map((v, k) => {
          return {
            h: Math.round((hsl.h + (360 / VARIATION_COUNT) * k) % 360),
            s: Math.round(hsl.s - (hsl.s / VARIATION_COUNT) * k),
            l: Math.round(hsl.l - hsl.l / VARIATION_COUNT)
          }
        })
        .filter((v) => {
          return v.l >= 20 && v.l <= 100
        })
        .map((hsl) => {
          /**
           * Here we want to reduce the saturation for "green" colors
           * to make them less visible compare to the others.
           */
          const isGreenish = hsl.h > 55 && hsl.h < 172

          return {
            ...hsl,
            s: isGreenish ? Math.min(hsl.s, 45) : hsl.s
          }
        })
        .filter((v, k) => k % 2)

      return variations
    })

    const inlineColors = colors.reduce((acc, colorArr) => {
      return acc.concat(colorArr)
    })

    return [...new Set(inlineColors)]
  })

  /**
   * @function getHexFromUuid
   * @param {string} uuid
   * @returns {string}
   */
  function getHexFromUuid (uuid) {
    return `#${parseInt(uuid, 16).toString(16).slice(0, 6)}`
  }

  /**
   * @function getColorFromUuid
   * @param {string} uuid
   * @returns {{
   *  background: { h: number, s: number, l: number },
   *  foreground: string
   * }}
   */
  function getColorFromUuid (uuid) {
    const hex = uuid ? getHexFromUuid(uuid) : '#f9f9f9'
    const rgb = hexToRgb(hex)
    const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b)

    const color = findNearestColor(hsl)

    return {
      background: color,
      foreground: getLumaFromRgb(rgb)
    }
  }

  return {
    getColorFromUuid
  }
}

// @ts-nocheck
export default {
  SET_ALERTS (state, alerts) {
    state.alerts = alerts
  },
  SET_IMPERSONATE_NAVBAR_VISIBLE (state, value) {
    state.impersonateClosed = !value
  },
  SET_BANNERS_HEIGHT (state, value) {
    state.bannersHeight = value
  },
  SET_WELCOME_DIALOG_CLOSED (state, value) {
    state.welcomeDialogClosed = value
  },
  SET_SHOW_NAV_BAR (state, val) {
    state.showNavBar = val
  },
  SET_WELCOME_VISIBLE (state, val) {
    state.welcomeVisible = val
  },
  SET_SIDEBAR_VALUE (state, value) {
    state.sidebarOpen = value
  },
  SET_SHOW_AUTH_CTA_BAR (state, value) {
    state.showAuthCtaBar = value
  }
}

/**
 * @function useMeetingComment
 * @param {Object} step
 * @param {string|{ rendez_vous: boolean, comment: string}} step.meeting
 * @returns {string|void}
 */
export function useMeetingComment (step) {
  const { meeting } = step

  if (!meeting) return
  if (typeof meeting === 'string') return meeting
  if (meeting.rendez_vous) return meeting.comment
}

// @ts-nocheck
import Storage from '@/services/Storage'

export default () => {
  /**
   * Welcome dialog behaviour.
   * If there is a storage value for the welcome dialog, don't show the welcome dialog.
   * This is caused by the fact that the is_first_connection always returns true even
   * after the first /me call. And since the /me is called in the shipment form, the
   * welcome dialog was shown there.
   */
  let welcomeDialogClosed = false
  const welcomeDialogStorage = Storage && Storage.getItem('ctk:ui:welcome-dialog')
  if (welcomeDialogStorage) {
    welcomeDialogClosed = true
  }

  /**
   * @const isSidebarOpen - Returns true if no storage found, storage value otherwise.
  */
  const lsSidebarOpen = Storage && Storage.getItem('ctk:ui:sidebar-open')
  const isSidebarOpen = lsSidebarOpen === null ? true : lsSidebarOpen === 'true'

  return {
    alerts: [],
    impersonateClosed: false,
    welcomeDialogClosed,
    bannersHeight: 0,
    showNavBar: true,
    welcomeVisible: false,
    sidebarOpen: isSidebarOpen,
    showAuthCtaBar: true
  }
}

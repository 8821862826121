import Vue from 'vue'
import { debounce } from 'underscore'
import Config from '@/services/Config'
import { isTesting } from '@/../utils/env'
import router from '@/router'

import ScriptPlugin from './../common/ScriptPlugin'

const defaultOptions = {
  debug: false,
  enableLinkTracking: true,
  trackInitialView: true,
  trackerFileName: 'matomo',
  trackerUrl: undefined,
  trackerScriptUrl: undefined,
  userId: undefined
}

class MatomoService {
  constructor (options = {}) {
    /**
     * @type {typeof defaultOptions}
     */
    this.options = options
    this.enabled = !isTesting

    // @ts-ignore
    this.trackEvent = debounce((...args) => {
      const tracker = this.tracker()
      if (this.enabled && tracker) {
        // @ts-ignore
        tracker.trackEvent.apply(this, args)
      }

      this.options.debug && console.debug('[matomo] Track event ', args)
    }, 300, true)
  }

  tracker () {
    if (typeof window.Piwik === 'undefined') return null
    return window.Piwik.getAsyncTracker()
  }

  setEnabled (value) {
    this.enabled = value
  }

  setCustomVariable (id, name, value, scope) {
    const tracker = this.tracker()
    tracker && tracker.setCustomVariable(id, name, value, scope)
  }

  setUserId (uuid) {
    const tracker = this.tracker()
    tracker && tracker.setUserId(uuid)
  }

  resetUserId () {
    const tracker = this.tracker()
    tracker && tracker.resetUserId()
  }

  trackPageView (title = null) {
    const tracker = this.tracker()
    if (this.enabled && tracker) tracker.trackPageView(title)
  }
}

class VueMatomo extends ScriptPlugin {
  constructor () {
    super()
    this.options = {}
  }

  piwikExists () {
    return new Promise((resolve, reject) => {
      if (isTesting) return resolve(true)

      const checkInterval = 50
      const timeout = 3000
      const waitStart = Date.now()

      const interval = setInterval(() => {
        if (window.Piwik) {
          clearInterval(interval)

          return resolve(true)
        }

        if (Date.now() >= waitStart + timeout) {
          clearInterval(interval)

          return reject(new Error(`[vue-matomo]: window.Piwik undefined after waiting for ${timeout}ms`))
        }
      }, checkInterval)
    })
  }

  /**
   * @function trackMatomoPageView
   * @param {import('vue').VueConstructor} vue
   */
  trackMatomoPageView (vue) {
    let title

    if (this.options.router) {
      const url = window.location
      const meta = this.options.router.currentRoute.meta

      this.options.debug && console.debug('[matomo] Tracking ' + url)

      title = meta.title
    }
    vue.prototype.$matomo.trackPageView(title)
  }

  /**
   * @function initMatomo
   * @param {import('vue').VueConstructor} vue
   */
  initMatomo (vue) {
    // Assign matomo to Vue
    const matomo = new MatomoService(this.options)
    vue.prototype.$matomo = matomo

    const { trackInitialView, router, enableLinkTracking } = this.options

    if (trackInitialView) {
      // Register first page view
      this.trackMatomoPageView(vue)
    }

    // Track page navigations if router is specified
    if (router) {
      router.afterEach((to, from) => {
        Vue.nextTick(() => {
          // Make matomo aware of the route change
          const tracker = matomo.tracker()
          if (tracker) {
            tracker.setReferrerUrl(from.fullPath)
            tracker.setCustomUrl(to.fullPath)

            this.trackMatomoPageView(vue, this.options)

            if (enableLinkTracking) {
              tracker.enableLinkTracking()
            }
          }
        })
      })
    }
  }

  /**
   * @function install
   * @param {import('vue').VueConstructor} vue
   * @param {typeof defaultOptions} [setupOptions]
   */
  install (vue, setupOptions = {}) {
    this.options = Object.assign({}, defaultOptions, setupOptions)

    const { host, siteId, trackerFileName, trackerUrl, trackerScriptUrl } = this.options
    this.scriptSrc = trackerScriptUrl || `${host}/${trackerFileName}.js`
    const trackerEndpoint = trackerUrl || `${host}/${trackerFileName}.php`

    window._paq = window._paq || []

    window._paq.push(['setTrackerUrl', trackerEndpoint])
    window._paq.push(['setSiteId', siteId])

    /**
     * Inject Vue Matomo even if the script itself is not loaded by the browser.
     * Issue caused by ad-blockers.
     */
    this.initMatomo(vue)

    this.loadScript()
      .then(() => this.piwikExists())
      .catch((error) => {
        if (error.target) {
          return console.error(
            `[vue-matomo] An error occurred trying to load ${error.target.src}. ` +
            'If the file exists you may have an ad- or trackingblocker enabled.'
          )
        }

        console.error(error)
      })
  }
}

const matomoInstance = new VueMatomo()
Vue.use(matomoInstance, {
  siteId: Config.get('matomo.siteId'),
  host: Config.get('matomo.host'),
  trackInitialView: false,
  router,
  debug: false
})

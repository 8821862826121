<template>
  <div class="offer-dialogs">
    <!-- Proposal -->
    <dialog-proposal
      v-model="dialogs.proposal"
      :renew="dialogs.proposalRenew"
      @proposal-created="dialogs.proposalConfirmation = true"
      data-test="proposal"
    />

    <dialog-proposal-confirmation
      v-model="dialogs.proposalConfirmation"
      data-test="proposal-confirmation"
    />

    <dialog-cancel-proposal
      v-if="proposalToCancel"
      v-model="dialogs.proposalCancellation"
      :proposal="proposalToCancel"
      :can-reset-current-offer="$route.name === 'Proposals'"
    />

    <!-- Booking -->
    <dialog-booking
      v-if="getCurrentOffer"
      v-model="dialogs.booking"
      @offer-booked="offerBooked"
      data-test="booking-dialog"
    />

    <dialog-booking-confirmation
      v-if="missionUuid"
      v-model="dialogs.bookingConfirmation"
      :mission-uuid="missionUuid"
    />

    <dialog-hide-offer
      v-if="getCurrentOffer && getCurrentOffer.uuid"
      v-model="dialogs.hideOffer"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import DialogCancelProposal from '@/views/Carriers/Offers/components/DialogCancelProposal/index.vue'
  import DialogBookingConfirmation from '@/views/Carriers/Offers/components/DialogBookingConfirmation/index.vue'
  import DialogProposalConfirmation from '@/views/Carriers/Offers/components/DialogProposalConfirmation/index.vue'
  import DialogProposal from '@/views/Carriers/Offers/components/DialogProposal/index.vue'
  import DialogBooking from '@/views/Carriers/Offers/components/DialogBooking/index.vue'
  import DialogHideOffer from '@/views/Carriers/Offers/components/DialogHideOffer/index.vue'

  import { EventBus } from '@/services/EventBus'

  /**
   * Component used to group all the dialog components needed for an offer, from the booking to
   * the proposal cancellation.
   *
   * @module component - offerDialogs
   */
  export default defineComponent({
    name: 'OfferDialogs',
    components: {
      DialogCancelProposal,
      DialogBookingConfirmation,
      DialogProposalConfirmation,
      DialogProposal,
      DialogBooking,
      DialogHideOffer
    },
    data () {
      return {
        dialogs: {
          proposalRenew: false,
          proposal: false,
          proposalCancellation: false,
          proposalConfirmation: false,
          booking: false,
          bookingConfirmation: false,
          hideOffer: false
        },
        missionUuid: null,
        proposalToCancel: null
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentOffer'
      ])
    },
    watch: {
      /**
       * TODO: Refactor and use composition API instead for both watchers.
       */
      'dialogs.booking': function (value) {
        this.setKeyboardStatus(!value)
      },
      'dialogs.proposal': function (value) {
        this.setKeyboardStatus(!value)
      }
    },
    mounted () {
      EventBus.$on('cancel-proposal-modal', (proposal) => {
        this.dialogs.proposalCancellation = true
        this.proposalToCancel = proposal
      })
      EventBus.$on('toggle-booking-modal', () => {
        this.dialogs.booking = true
        if (this.getCurrentOffer) {
          this.retrieveOffersSuggestion(this.getCurrentOffer)
            .catch(() => {})
        }
      })
      EventBus.$on('toggle-proposal-modal', (renew) => {
        this.toggleProposalModal(renew)
      })
      // @ts-ignore
      EventBus.$on('toggle-dialog', (dialog, value) => {
        // @ts-ignore
        this.dialogs[dialog] = value
      })
      EventBus.$on('confirm-hide-modal', () => {
        this.dialogs.hideOffer = true
      })
    },
    beforeDestroy () {
      ['confirm-hide-modal', 'toggle-dialog', 'cancel-proposal-modal', 'toggle-booking-modal', 'toggle-proposal-modal']
        .forEach((e) => EventBus.$off(e))
    },
    methods: {
      ...mapActions('offers', [
        'setKeyboardStatus',
        'retrieveOffersSuggestion'
      ]),
      toggleProposalModal (renew = false) {
        this.dialogs.proposalRenew = renew
        this.dialogs.proposal = true
        this.setKeyboardStatus(true)
      },
      /**
       * Called when an offer was booked
       * @function offerBooked
       */
      // @ts-ignore
      offerBooked (missionUuid) {
        this.missionUuid = missionUuid
        this.dialogs.bookingConfirmation = true
      }
    }
  })
</script>

<template>
  <div class="ctk-notifications-dialkog-item-footer tw-flex tw-items-center">
    <ui-ctk-icon
      name="expiration"
      class="tw-text-gray-600 tw-text-5xl"
      data-test="icon"
    />
    <p
      class="tw-text-gray-700 tw-mb-0"
      data-test="content"
    >
      <span
        v-text="$t('app.paragraphs.shipment_expiring_soon')"
      />
      <UiLink
        :to="{
          name: 'Shipment',
          params: {
            uuid: shipment.uuid,
          },
          query: {
            'update-expires-at': 1
          }
        }"
        class="tw-ml-2"
        v-text="$t('app.buttons.postpone_expires_at')"
        v-matomo="{
          click: {
            category: 'Global',
            action: 'Notifications',
            name: 'Expiration Delay Clicked'
          }
        }"
        standalone
        data-test="postpone"
        @click.native="$emit('click')"
      />
    </p>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkNotificationsDialkogItemFooter
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'CtkNotificationsDialkogItemFooter',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-notifications-dialkog-item-footer {
    border-top: 1px solid $divider;
  }

</style>

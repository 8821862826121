
    const jsonData = {
  "faq.title": "Help center",
  "faq.titles.guide": "Short guide to prepare your shipment",
  "faq.titles.question": "Got a question?",
  "faq.titles.documents": "Documents to download",
  "faq.titles.loads_prepared": "How should the goods be prepared?",
  "faq.titles.goods_dispute": "What happens in case of dispute over the goods?",
  "faq.titles.responsibilities": "What am I responsible for?",
  "faq.titles.claims": "What can I claim and how?",
  "faq.titles.carrier.documents_needed": "What documentation do I need?",
  "faq.titles.shipper.infos": "What information shall I provide to the carrier?",
  "faq.titles.shipper.documents_needed": "What documents does the carrier need?",
  "faq.titles.shipper.hours": "Will I be informed of the carrier's precise visit time slots?",
  "faq.labels.format": "Format: {format}",
  "faq.values.delivery_order": "Standard CMR",
  "faq.buttons.download": "Download",
  "faq.buttons.contact": "Contact us",
  "faq.paragraphs.contact": "Our teams are committed to responding to you as soon as possible!",
  "faq.paragraphs.introduction": "Any questions about Chronotruck? You're in the right place.",
  "faq.paragraphs.carrier.documents_needed": "The carrier must come up with a consignment note (usually called a CMR) which summarizes the main information on the shipment (detailed addresses, merchandise characteristics).\n",
  "faq.paragraphs.carrier.documents_needed.cmr_model": "If extraordinarily your CMR does not suit the shipper, please use a standard one as [the one available here]({cmr_example}).\n",
  "faq.paragraphs.carrier.documents_needed.specific_doc": "Moreover, if the shipment requires some specific documents such as traffic or access authorizations (for customs or delivery), the shipper has to provide them to you.\n",
  "faq.paragraphs.carrier.documents_needed.ask": "Do not hesitate to ask him if you have any doubts, in particular by contacting him in advance.\n",
  "faq.paragraphs.shipper.documents_needed": "The carrier must come up with a CMR (usually called a delivery receipt) which summarizes the main information on the shipment (detailed addresses, merchandise characteristics). If extraordinarily the carrier does not have one or if its CMR does not suit you, please use a standard one as [the one available here]({cmr_example}).\n",
  "faq.paragraphs.shipper.documents_needed.particular_doc": "Moreover, if the shipment requires some specific documents such as traffic or access authorizations (for customs or delivery), you must provide them to the carrier by contacting him in advance if necessary.\n",
  "faq.paragraphs.carrier.loads_prepared": "The shipper is responsible for the merchandise preparation of the goods and for non visible defects which he must detail on the consignment note.\n",
  "faq.paragraphs.carrier.loads_prepared.loading": "You are responsible for loading, stacking, stowing and blocking merchandise under three tons.\n",
  "faq.paragraphs.carrier.loads_prepared.loading_three_tons_more": "For goods weighing more than three tons, loading, stacking, stowing and blocking must be performed according to the shipper's recommendations.\n",
  "faq.paragraphs.carrier.loads_prepared.signal_reserve": "Be vigilant and write on the consignment note all disagreement you may have regarding the preparation or the state of the goods.\n",
  "faq.paragraphs.carrier.loads_prepared.loading_directive": "Also note any disagreement you may have with the shipper's recommendations on loading, stacking, stowing and blocking if you believe they could result in damaged goods: write it on the consignment note and have it signed by the customer.\n",
  "faq.paragraphs.carrier.loads_prepared.important_doubt": "In case of major concern, you could even refuse to load the goods and ask for an immobilization compensation (see below).\n",
  "faq.paragraphs.carrier.loads_prepared.correspondig_merchandise": "The prepared load must correspond to the dimensions that the customer indicated when ordering.\n",
  "faq.paragraphs.shipper.loads_prepared": "You are responsible for the preparation of the goods as well as any hidden defects that must be reported to the carrier.\n",
  "faq.paragraphs.shipper.loads_prepared.damage_responsability": "If damage should occur as a result of the preparation or a non-apparent defect of the goods, you may be held responsible. Be vigilant!\n",
  "faq.paragraphs.shipper.loads_prepared.three_tons_more": "For shipments over 3 tons, loading, wedging, stowing must be done according to the guidelines you give to the carrier and that he must accept.\n",
  "faq.paragraphs.carrier.goods_dispute": "Yourself - as well as the client - should report any dispute on the consignment note, on pickup as on drop-off. This protects you in the event of a claim.\n",
  "faq.paragraphs.carrier.goods_dispute.signal_problem": "Report the problem as soon as you have knowledge (on pickup or drop-off).\n",
  "faq.paragraphs.carrier.goods_dispute.merchandise_responsability": "If you don't signal your disagreement, and damages on goods occur, you'd be held liable by default, because you are responsible for the goods during the journey.\n",
  "faq.paragraphs.carrier.goods_dispute.case_provided_in_contract": "We appreciate all the cases provided for by the Standard General Contract of the Ministry of Transport or the CMR convention in Europe.\n",
  "faq.paragraphs.carrier.goods_dispute.adjustment": "Also, we can decide to adjust the fare according to the penalties detailed by our General Terms and Conditions for Use and this contract which allows you to be compensated directly by Chronotruck in the foreseen cases, or to increase billing if the responsibility lies with the customer.\n",
  "faq.paragraphs.carrier.goods_dispute.responsability": "Conversely, you keep all the responsibilities provided by these Terms and the General Contract or the CMR convention and you agree to compensate the client for the various cases provided.\n",
  "faq.paragraphs.shipper.goods_dispute": "Yourself - as well as the carrier - must report any dispute on the CMR, on pickup as on delivery.\n",
  "faq.paragraphs.shipper.goods_dispute.claim": "This protects you in the event of a claim. For example, if the carrier damaged the goods on when loading or during the trip, report it as soon as you have knowledge (pickup or delivery).\n",
  "faq.paragraphs.shipper.goods_dispute.loading_default": "Conversely, the carrier will report any lack of preparation of the goods or defect that pre-existed pickup.\n",
  "faq.paragraphs.shipper.goods_dispute.loading_default_three_tons_more": "He can also mention reservations on your loading, wedging or stowing guidelines for loads over 3 tons.\n",
  "faq.paragraphs.shipper.goods_dispute.contract_case": "We appreciate all the cases provided for by the Standard General Contract of the Ministry of Transport or by the CMR convention in Europe and, if you signed up for it, by our “ad valorem” insurance.\n",
  "faq.paragraphs.shipper.goods_dispute.compensation": "If you had subscribed to the “ad-valorem” insurance, we compensate cases provided by this insurance at the value of the commodities (up to €50,000 if you are located in France).\n",
  "faq.paragraphs.shipper.goods_dispute.penalty": "We can also decide to adjust the service according to the penalties stipulated in our General Terms and Conditions for Use, allowing you to be compensated directly by Chronotruck in the various cases expected (delay, damage, etc.).\n",
  "faq.paragraphs.carrier.responsibilities": "You may be liable for compensation regarding all disputes you are responsible for, notably due to a lack of compliance with the contract terms. This can result in two types of major defaults:\n",
  "faq.paragraphs.carrier.responsibilities.case_late_and_damage_goods": "* delays for the client you will have to compensate beyond certain limits [according to our terms of use]({terms_of_service}#art14). \n* damages on the goods if they result from your actions and which will have to be compensated for based on the [French Government Contrat Type]({terms_of_service}#ministere) according to a value per kilogram transported.\n",
  "faq.paragraphs.shipper.responsibilities": "You may be liable to compensation for all claims for which you may be responsible, notably due to a lack of information on the shipment, a problem of merchandise preparation, a non-apparent defect you may not have reported... This can result in two sorts of major damage:\n",
  "faq.paragraphs.shipper.responsibilities.cases": "* delays or immobilizations of the vehicle that the carrier may charge on you over certain limits [according to our TOS]({terms_of_service}#art14)\n* damage on the goods for which you will not be reimbursed if you are responsible, and compensation to the carrier if it has also damaged their vehicle.\n",
  "faq.paragraphs.carrier.claims": "On the other hand, for disputes where the shipper is responsible, due to problems of incomplete information or preparation of the goods, you can claim additional compensation mainly for downtime of your vehicle, delays or services not planned initially [according to our terms of use]({terms_of_service}#art14).\n",
  "faq.paragraphs.carrier.claims.reserve": "In all these cases, write any problem on the consignment note regarding time schedules or the condition of the goods, on pickup as well as on drop-off, because your notes can prove the responsibility of all involved parties.\n",
  "faq.paragraphs.carrier.claims.signal_reserve": "Report them precisely on the consignment note and inform us: we will intervene according to our role as a freight forwarder.\n",
  "faq.paragraphs.shipper.claims": "Conversely, in the case of disputes for which the carrier is responsible, you can ask:\n",
  "faq.paragraphs.shipper.claims.discount_and_compensation": "* a price reduction in case of failure of the carrier to fulfill its commitments, notably on the dates and slots for pickup or delivery [according to our TOS]({terms_of_service}#art13)\n\n* if you signed up for the “ad valorem” insurance, compensation for lack of delivery, partial deliveries and material damage according to the terms of our insurance partnership with AXA which covers the value of your property up to 50,000 euros if your company is located in France.\n",
  "faq.paragraphs.shipper.claims.reserve": "In all these cases, the presence of reservations on schedules and on the condition of the merchandise in case of a problem, both on pickup as on delivery is paramount, because they prove the responsibility of all those involved.\n",
  "faq.paragraphs.shipper.claims.signal_reserve": "You will have to confirm them by registered mail to Chronotruck within 3 days following delivery: otherwise, we might not be able to support your claim. You will also need to provide us with invoices proving the value of the damaged property.\n",
  "faq.paragraphs.shipper.infos": "You need to give all relevant information to your carrier, on the merchandise, but also on the pickup and delivery addresses or on potential procedures to follow (access, handling).\n",
  "faq.paragraphs.shipper.infos_contact_carrier": "If you do not provide it during the quotation process on the Chronotruck site, please contact Chronotruck and provide the information. The sooner you provide the information, the easier it is to react and anticipate potential problems.\n",
  "faq.paragraphs.shipper.hours": "We ask all carriers to inform customers as precisely as possible.\n",
  "faq.paragraphs.shipper.hours.contact_carrier": "You can also contact your carrier whose information appears in the shipment's details, once a driver has booked your shipment.\n",
  "faq.paragraphs.shipper.hours.meeting": "If you did not choose a tight time slot for pickup or delivery (usually less than 2 hours), you can contact us so we can organize a precise appointment: it is usually an option billed by the carrier.\n"
};
    module.exports = jsonData;
  
import axios from 'axios'

import { env } from '@/../utils/env'
import Config from '@/services/Config'

import authorization from './interceptors/authorization'
import acceptLanguage from './interceptors/acceptLanguage'
import minLengthOfferSearch from './interceptors/minLengthOfferSearch'
import multiPallet from './interceptors/multiPallet'
import notAllowed from './interceptors/notAllowed'
import internalError from './interceptors/internalError'
import networkError from './interceptors/networkError'
import impersonate from './interceptors/impersonate'
import unauthorized from './interceptors/unauthorized'
import nullableDriver from './interceptors/nullableDriver'

const axiosInstance = axios.create({
  baseURL: Config.get(`api.base.${env}`),
  validateStatus (status) {
    return status >= 200 && status < 300
  }
})

const INTERCEPTORS = [
  authorization,
  minLengthOfferSearch,
  acceptLanguage,
  impersonate,
  multiPallet,
  nullableDriver
]

for (const interceptor of INTERCEPTORS) {
  axiosInstance.interceptors.request.use(interceptor)
}

const RESPONSE_INTERCEPTORS = [
  networkError,
  notAllowed,
  internalError,
  unauthorized
]

for (const interceptor of RESPONSE_INTERCEPTORS) {
  axiosInstance.interceptors.response.use(interceptor.response, interceptor.error)
}

export default axiosInstance

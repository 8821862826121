import { i18n } from '@/locales'
import useCountryLists from '@/composables/useCountryLists'

/**
 * Override the default VeeValidate "mime" rule to change the error message
 * & use a more usefull mime format.
 */
/** @type {import('vee-validate/dist/types/types').ValidationRuleSchema} */
const validator = {
  /** @type {import('vee-validate/dist/types/types').ValidationMessageGenerator} */
  message () {
    return i18n.t('validator.country').toString()
  },
  /** @param {any} args */
  validate (value, args) {
    const { isCountryOpened } = useCountryLists()
    const countryCode = args[0]

    return !!isCountryOpened(countryCode) || false
  }
}

export default {
  name: 'country',
  validator
}

// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class TosResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/terms-of-service'
  }

  getContent (...args) {
    return this.action('GET', '/{locale}', ...args)
  }

  accept (...args) {
    return this.action('POST', '/accept', ...args)
  }
}

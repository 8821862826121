// @ts-nocheck

import * as Sentry from '@sentry/browser'
import { Company } from '@/resources'

export default {
  retrieveDocuments ({ commit, rootGetters }) {
    return Company.onboarding({
      cid: rootGetters['auth/getCid']
    })
      .then(res => {
        if (!res) {
          throw new Error('Could not retrieve the onboarding documents')
        }

        commit('SET_DOCUMENTS', res.data.documents)

        return res
      })
      .catch(err => {
        Sentry.captureException(err, {
          extra: {
            err
          }
        })
      })
  }
}

// @ts-nocheck
import Vue from 'vue'
import Vuex from 'vuex'

import faq from './modules/faq'
import drivers from './modules/drivers'
import missions from './modules/missions'
import billing from './modules/billing'
import offers from './modules/offers'
import auth from './modules/auth'
import shipments from './modules/shipments'
import search from './modules/search'
import ui from './modules/ui'
import onboarding from './modules/onboarding'
import notifications from './modules/notifications'
import account from './modules/account'
import externalRequests from './modules/external_requests'

import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    faq,
    drivers,
    missions,
    billing,
    offers,
    shipments,
    auth,
    account,
    search,
    ui,
    onboarding,
    notifications,
    externalRequests
  },
  actions,
  getters,
  mutations,
  state,
  strict: true
})

export default store

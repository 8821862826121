// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class RegistrationResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/registration'
  }

  VAT (...args) {
    return this.action('GET', '/vat/{vat}', ...args)
  }

  carrier (...args) {
    return this.action('GET', '/carrier', ...args)
  }

  shipper (...args) {
    return this.action('GET', '/shipper', ...args)
  }

  fetchUserInvite (...args) {
    return this.action('GET', '/user-invites/{uid}', ...args)
  }

  saveCarrier (...args) {
    return this.action('POST', '/carrier', ...args)
  }

  saveShipper (...args) {
    return this.action('POST', '/shipper', ...args)
  }

  userInvite (...args) {
    return this.action('POST', '/user-invites/{uid}', ...args)
  }
}

// @ts-nocheck
import Storage from '@/services/Storage'

import { Company } from '@/resources'

export default {
  setImpersonateNavbarHidden ({ commit }) {
    commit('SET_IMPERSONATE_NAVBAR_VISIBLE', false)
  },
  setBannersHeight ({ commit, state }, value) {
    if (state.bannersHeight !== value) {
      commit('SET_BANNERS_HEIGHT', value)
    }
  },
  setWelcomeDialogClosed ({ commit }) {
    Storage.setItem('ctk:ui:welcome-dialog', true)
    commit('SET_WELCOME_DIALOG_CLOSED', true)
  },
  setWelcomeVisible ({ commit }, value) {
    commit('SET_WELCOME_VISIBLE', value)
  },
  setSidebarOpen ({ commit }, value) {
    Storage && Storage.setItem('ctk:ui:sidebar-open', value)
    commit('SET_SIDEBAR_VALUE', value)
  },
  toggleSidebar ({ dispatch, getters }) {
    const sidebarValue = !getters.isSidebarOpen
    dispatch('setSidebarOpen', sidebarValue)
  },
  retrieveAlerts ({ commit, rootGetters }) {
    return Company.alerts({
      cid: rootGetters['auth/getCid']
    })
      .then(response => {
        if (response.status === 200) {
          commit('SET_ALERTS', response.data.items)
        }

        return response
      })
      .catch(() => {})
  },
  setShowAuthCtaBar ({ commit }, value) {
    commit('SET_SHOW_AUTH_CTA_BAR', value)
  }
}

// @ts-nocheck
export default {
  getStoredMissions (state) {
    return state.missions.items
  },
  getCurrentMission (state) {
    /**
     * TODO: Instead of using the currentMission object, should use
     * the missions array & use the find method to get
     * the current mission, with the currentMission uuid.
     */
    return state.currentMission
  },
  getCurrentMissionStatus (state) {
    return state.currentMissionStatus
  },
  getCurrentMissionUuid (state, g) {
    return g.getCurrentMission.uuid
  },
  getMyMetrics (state) {
    return state.metrics.me
  },
  getCompanyMetrics (state) {
    return state.metrics.company
  },
  getMyMetricsCount (state) {
    return state.metrics.me.planned + state.metrics.me.in_progress
  },
  getCompanyMetricsCount (state) {
    return state.metrics.company.planned + state.metrics.company.in_progress
  },
  getCurrentMissionState (state, g) {
    return g.getCurrentMission.state
  },
  getCurrentMissionPickupState (state, g) {
    return g.getCurrentMission && g.getCurrentMission.pickup && g.getCurrentMission.pickup.state
  },
  getCurrentMissionDeliveryState (state, g) {
    return g.getCurrentMission && g.getCurrentMission.delivery && g.getCurrentMission.delivery.state
  },
  getCurrentMissionReference (state, g) {
    return g.getCurrentMission.reference
  },
  getCurrentMissionShipper (state, g) {
    return g.getCurrentMission.shipper
  },
  getCurrentMissionDriver (state, g) {
    return g.getCurrentMission.driver
  },
  getCurrentMissionOwner (state, g) {
    return g.getCurrentMission.owner
  },
  getMissionUuidToShow (state) {
    return state.missionUuidToShow
  },
  getMissionsItineraries (state) {
    return state.itineraries
  },
  getMissionsMeta (state) {
    return state.missions.meta
  },
  getFetchMissionsActive (state) {
    return state.fetchMissionsActive
  },
  getMissionsFilters (state) {
    return state.missions.filters
  },
  getBilling (state) {
    return state.billing
  }
}
